import React from "react";
import { ReviewDetails } from "../../../../components";
import "./ReviewsContainer.scss";

import { useSelector } from "react-redux";

const ReviewsContainer = () => {
  const employeeReviewsSubSlice = useSelector(
    (state) => state.employeeSlice.Reviews
  );

  if (employeeReviewsSubSlice.res)
    return (
      <div className="reviews-info">
        <div className="reviews-content-gap">
          {employeeReviewsSubSlice.status === "success" &&
            employeeReviewsSubSlice.res.length > 0 &&
            employeeReviewsSubSlice?.res?.map((employeeReview) => (
              <ReviewDetails
                key={employeeReview.id}
                comments={employeeReview.feedback}
                rating={(employeeReview.attendance + employeeReview.professionalism + employeeReview.patient_care)/3}
                name={employeeReview.facility_name}
              />
            ))}

          {employeeReviewsSubSlice.status === "success" &&
            employeeReviewsSubSlice.res.length === 0 && (
              <>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "160%",
                    color: "#7183a5",
                  }}
                >
                  No reviews found
                </div>
                {/* <ReviewDetails
                  key="1"
                  comments="no review Found"
                  // rating={employeeReview.rating}
                  // name={employeeReview.facility_name}
                /> */}
              </>
            )}
        </div>
      </div>
    );
};
export default ReviewsContainer;
