import { AppBar, ProfileSettingsBtn, SideBarMenu } from "../../components";
import {
  ChevronLeft,
  FacilityBuilding,
  FacilityEmail,
  FacilityLocation,
} from "../../assets/images";

import { useDispatch } from "react-redux";
import Carousel from "react-material-ui-carousel";

import { fetchAgencyProfile } from "../../redux/agencySlice";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getImage } from "../../utils/getAwsFile";

const AgencyDetails = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [pictures, setPicture] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchAgencyProfile({ uuid }));
  }, []);

  const agency = useSelector((state) => state.agencySlice.Profile.res);
  const agencyProfileSubSlice = useSelector(
    (state) => state.agencySlice.Profile
  );

  useEffect(() => {
    setPicture([]);
    if (agencyProfileSubSlice?.res?.files) {
      Promise.all(
        agencyProfileSubSlice?.res?.files.map((fileObj) =>
          getImage(fileObj.aws_object_key)
        )
      ).then((data) => {
        setPicture(data);
        setLoading(false);
      });
    }
    if (
      agencyProfileSubSlice?.status == "success" &&
      agencyProfileSubSlice?.res?.files?.length == "0"
    )
      setLoading(false);
  }, [agencyProfileSubSlice]);

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        {!loading ? (
          <div className="facility-profile-container">
            <header>
              <ChevronLeft
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              />
              <span>Back</span>
            </header>
            {pictures.length > 0 && (
              <section className="images-section">
                <div
                  style={{
                    maxWidth: "100%",
                    minWidth: "100%",
                    // maxHeight: "516px",
                    // overflow: "hidden",
                  }}
                >
                  <Carousel
                    interval="10000"
                    navButtonsAlwaysVisible="true"
                    height="514px"
                  >
                    {pictures.map((picture, index) => (
                      <>
                        <p style={{ textAlign: "center" }} key={index}>
                          <img
                            src={"data:image/png;base64," + picture}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </p>
                      </>
                    ))}
                  </Carousel>
                </div>
              </section>
            )}
            {/* <section className="images-section">
              <img src={HospitalFacility} className="main-img" />
              <div className="secondary-imgs">
              <img src={HospitalFacility} className="secondary-img" />
              <img src={HospitalFacility} className="secondary-img" />
              <img src={HospitalFacility} className="secondary-img" />
              <img src={HospitalFacility} className="secondary-img" />
            </div>
            </section> */}
            <h2>Details</h2>
            <section className="facility-detail-section">
              <div className="top-bar">
                <div className="facility-detail">
                  <div className="icon-wrapper">
                    <FacilityBuilding />
                  </div>
                  <div className="facility-detail-text">
                    <div className="title">Full Name</div>
                    <div className="details">{agency?.name}</div>
                  </div>
                </div>
                <div className="facility-detail">
                  <div className="icon-wrapper">
                    <FacilityEmail />
                  </div>
                  <div className="facility-detail-text">
                    <div className="title">Email</div>
                    <div className="details">
                      {agency?.profile?.user?.email}
                    </div>
                  </div>
                </div>
                <div className="facility-detail">
                  <div className="icon-wrapper">
                    <FacilityLocation />
                  </div>
                  <div className="facility-detail-text">
                    <div className="title">Location</div>
                    <div className="details">{"615 6th New York"}</div>
                  </div>
                </div>
              </div>
              <h3 className="bold-text"> Description </h3>
              <p className="description-text">{agency?.about}</p>
            </section>
          </div>
        ) : (
          <div style={{ marginTop: 64 }} className="loader margin"></div>
        )}
      </div>
    </div>
  );
};

export default AgencyDetails;
