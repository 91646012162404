export function getMonthBoundaries(year, month) {
  function getLastDayOfMonth(year, month) {
    const date = new Date(year, month, 0);
    return date.getDate();
  }

  const fromDate = `${year}-${month}-1T00:00:00.000Z`
  const toDate = `${year}-${month}-${getLastDayOfMonth(year, month)}T23:59:59.999Z`
  return { fromDate, toDate };
}

