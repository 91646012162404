import React, { useState } from "react";
import {
  DashboardIcon,
  ShiftsIcon,
  MessageIcon,
  CalendarIcon,
  NodSideBarLogo,
  SideMenuOptionsIcon,
  InfoIcon,
  SideBarActiveEmployees,
  SideBarPendingEmployees,
  SideBarAgency,
  SideBarHome,
  Timesheet,
} from "../../assets/images";
import "./sideBarMenu.scss";

import useCookies from "react-cookie/cjs/useCookies";

import { Link, useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

import { useEffect } from "react";

const SideBarMenu = () => {
  const [activeMenuOption, setActiveMenuOption] = useState();
  const location = useLocation();

  useEffect(() => {
    cookies.sideBarOpen === undefined && setCookie("sideBarOpen", "1");
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname == "/agency-dashboard" ||
      location.pathname == "/admin-dashboard"
    )
      setActiveMenuOption("dashboard");
    else if (location.pathname === "/shift-requests")
      setActiveMenuOption("shifts");
    else if (location.pathname.startsWith("/applications"))
      setActiveMenuOption("Pending-Employees");
    else if (location.pathname.startsWith("/timesheet"))
      setActiveMenuOption("timesheet");
    else if (location.pathname.startsWith("/calendar"))
      setActiveMenuOption("calendar");
    else if (location.pathname.startsWith("/available-now"))
      setActiveMenuOption("Active-Employees");
    else if (location.pathname.startsWith("/agency-list"))
      setActiveMenuOption("agencies");
  }, []);

  const [cookies, setCookie] = useCookies(["sideBarOpen"]);

  const sideBarOpen = cookies.sideBarOpen;

  const invertSideBarOpen = () =>
    sideBarOpen === "0"
      ? setCookie("sideBarOpen", "1")
      : setCookie("sideBarOpen", "0");

  return (
    <div className="sideBar">
      <div
        className={`sideBar_container ${sideBarOpen === "0" && "active-menu"}`}
      >
        <div className="sideBar_container_logo">
          <Link to="/">
            <NodSideBarLogo className="logo" />
          </Link>
          <SideMenuOptionsIcon
            onClick={invertSideBarOpen}
            style={{ display: "block" }}
          />
        </div>
        <div className="sideBar_content">
          {cookies.token.user_type == "Facility" && (
            <>
              <Link to="/" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "dashboard" && "active"}`}
                >
                  <DashboardIcon />
                  <p>Dashboard</p>
                </div>
              </Link>
              <Link to="/shift-requests" className="sidebar-link">
                <div className={`${activeMenuOption === "shifts" && "active"}`}>
                  <ShiftsIcon /> <p>Shifts</p>
                </div>
              </Link>
              <Link to="/timesheet" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "timesheet" && "active"}`}
                >
                  <Timesheet />
                  <p>Timesheet</p>
                </div>
              </Link>
              <Link to="/calendar" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "calendar" && "active"}`}
                >
                  <CalendarIcon />
                  <p>Calendar</p>
                </div>
              </Link>
            </>
          )}
          {cookies.token.user_type == "Agency" && (
            <>
              <Link to="/agency-dashboard" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "dashboard" && "active"}`}
                >
                  <DashboardIcon />
                  <p>Dashboard</p>
                </div>
              </Link>
              <Link to="/shift-requests" className="sidebar-link">
                <div className={`${activeMenuOption === "shifts" && "active"}`}>
                  <ShiftsIcon /> <p>Shifts</p>
                </div>
              </Link>
              <Link to="/calendar" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "calendar" && "active"}`}
                >
                  <CalendarIcon />
                  <p>Calendar</p>
                </div>
              </Link>
              <Link to="/timesheet" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "timesheet" && "active"}`}
                >
                  <Timesheet />
                  <p>Timesheet</p>
                </div>
              </Link>
              <Link to="/applications" className="sidebar-link">
                <div
                  className={`${
                    activeMenuOption === "Pending-Employees" && "active"
                  }`}
                >
                  <SideBarPendingEmployees />
                  <p>Pending Employee Applications</p>
                </div>
              </Link>
              <Link to="/available-now" className="sidebar-link">
                <div
                  className={`${
                    activeMenuOption === "Active-Employees" && "active"
                  }`}
                >
                  <SideBarActiveEmployees />
                  <p>Active Employees/ Facilities</p>
                </div>
              </Link>
            </>
          )}

          {cookies.token.user_type == "Admin" && (
            <>
              <Link to="/admin-dashboard" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "dashboard" && "active"}`}
                >
                  <SideBarHome />
                  <p>Dashboard</p>
                </div>
              </Link>
              <Link to="/agency-list" className="sidebar-link">
                <div
                  className={`${activeMenuOption === "agencies" && "active"}`}
                >
                  <SideBarAgency /> <p>Agencies</p>
                </div>
              </Link>
            </>
          )}

          {/* <div className="border">
            <InfoIcon />
            <p>Help</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default SideBarMenu;
