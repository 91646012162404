import React from "react";
import "./SuccessPage.scss";
import LOGO from "../../assets/images/nod-logo.svg";

const SuccessPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "20px",
        alignContent: "center",
        paddingTop: "24px",
        height: "100vh",
      }}
    >
      <img className="success" src={LOGO} />
      <h1 className="success">Your password has been successfully updated!</h1>
    </div>
  );
};

export default SuccessPage;
