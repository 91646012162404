import React from "react";
import { useSelector } from "react-redux";
import { Eye } from "../../assets/images";
import { getFileSignedUrl } from "../../utils/getAwsFile";
import "./documentDetails.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const documentExits = (documentName, employeeProfileRes) => {
  return employeeProfileRes.files.find((doc) => doc.type === documentName);
};

const DocumentDetails = ({ icon, label, fileName }) => {
  const employeeProfileRes = useSelector(
    (state) => state.employeeSlice.Profile.res,
  );

  async function downloadDocument(documentName, employeeProfileRes) {
    const aws_object_key = employeeProfileRes.files.find(
      (doc) => doc.type === fileName,
    )?.aws_object_key;

    if (!aws_object_key)
      return toast.error("Document not found", {
        hideProgressBar: true,
      });

    const urlPromise = getFileSignedUrl(aws_object_key);
    const downloadUrl = await toast.promise(urlPromise, {
      pending: {
        render: "Preparing document download",
        hideProgressBar: true,
      },
      success: {
        render: "Document ready for download",
        hideProgressBar: true,
      },
      error: {
        render: "Document preparation for download failed",
        hideProgressBar: true,
      },
    });

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", aws_object_key.split("/").pop()); // to download with the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const does = documentExits(label.toUpperCase(), employeeProfileRes);

  return (
    <div className="document_details">
      <div className="resume-info">
        {icon}
        <label>{label}</label>
      </div>

      <div className="d-flex align-center">
        <div className="eye_ball-border" />
        <Eye
          className={
            documentExits(fileName, employeeProfileRes)
              ? "cursor-pointer present-document"
              : ""
          }
          onClick={() =>
            downloadDocument(label.toUpperCase(), employeeProfileRes)
          }
        />
      </div>
    </div>
  );
};

export default DocumentDetails;
