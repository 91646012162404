import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import {
  AgencyDashboard,
  AgencyListContainer,
  AgencySettingsContainer,
  ApplicationsContainer,
  EmployeeApplicationContainer,
  EmployeeDetailsPage,
  FacilityDetails,
  HomePage,
  Login,
  NotificationHomePage,
  ProfileSettingsPage,
  RequestsContainer,
  SuperAdminDashboard,
  TimeSheetPage,
  ViewAllAvailableNow,
  ViewAllTopRated,
} from "./containers";

import CurrentContainer from "./containers/currentContainer/currentContainer";
import { ChangeImage } from "./containers/changeImage/changeImage";
import AdminLoginPage from "./containers/adminLoginPage/adminLoginPageContainer";
import AgencyDetails from "./containers/agencyDetails/agencyDetails";
import AllTimeSheetPage from "./containers/allTimesheetPage/AllTimeSheetPage";
import CalendarPage from "./containers/calendarPage/CalendarPage";
import ChangePassword from "./containers/changePassword/ChangePassword";
import SuccessPage from "./containers/successPage/SuccessPage";
import ChangeMyPassword from "./containers/changeMyPassword/changeMyPassword";
import ForgotPassword from "./containers/forgotPassword/forgotPassword";

function RoutesComponent({ userType }) {
  if (!userType) {
    return (
      <Routes>
        {/* Routes for non-authenticated users */}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/login-admin" element={<AdminLoginPage />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    );
  } else if (userType === "Facility") {
    return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/employee-details/:uuid" element={<EmployeeDetailsPage />} />
        <Route path="/timesheet/:uuid" element={<TimeSheetPage />} />
        <Route path="/timesheet" element={<AllTimeSheetPage />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/notifications" element={<NotificationHomePage />} />
        <Route path="/profile" element={<ProfileSettingsPage />} />
        <Route path="/shift-requests" element={<RequestsContainer />} />
        <Route path="/top-rated" element={<ViewAllTopRated />} />
        <Route path="/available-now" element={<ViewAllAvailableNow />} />
        <Route path="/facility-details/:uuid" element={<FacilityDetails />} />
        <Route path="/change-password" element={<ChangeMyPassword />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
        <Route path="/change-image" element={<ChangeImage />} />
        <Route path="/cc" element={<CurrentContainer />} />
      </Routes>
    );
  } else if (userType === "Agency") {
    return (
      <Routes>
        <Route path="/notifications" element={<NotificationHomePage />} />
        <Route path="/login" element={<Navigate to="/agency-dashboard" />} />
        <Route path="/calendar" element={<CalendarPage />} />
        <Route path="/profile" element={<ProfileSettingsPage />} />
        <Route path="/" element={<Navigate to="/agency-dashboard" />} />
        <Route path="/agency-dashboard" element={<AgencyDashboard />} />
        <Route path="/applications" element={<EmployeeApplicationContainer />} />
        <Route path="/shift-requests" element={<RequestsContainer />} />
        <Route path="/available-now" element={<ApplicationsContainer />} />
        <Route path="/employee-details/:uuid" element={<EmployeeDetailsPage />} />
        <Route path="/facility-details/:uuid" element={<FacilityDetails />} />
        <Route path="/timesheet/:uuid" element={<TimeSheetPage />} />
        <Route path="/timesheet" element={<AllTimeSheetPage />} />
        <Route path="/change-password" element={<ChangeMyPassword />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
        <Route path="/change-image" element={<ChangeImage />} />
      </Routes>
    );
  } else if (userType === "Admin") {
    return (
      <Routes>
        <Route path="/login-admin" element={<Navigate to="/admin-dashboard" />} />
        <Route path="/" element={<Navigate to="/admin-dashboard" />} />
        <Route path="/admin-dashboard" element={<SuperAdminDashboard />} />
        <Route path="/agency-list" element={<AgencyListContainer />} />
        <Route path="/agency-settings/:uuid" element={<AgencySettingsContainer />} />
        <Route path="/profile" element={<ProfileSettingsPage />} />
        <Route path="/agency-details/:uuid" element={<AgencyDetails />} />
        <Route path="/notifications" element={<NotificationHomePage />} />
        <Route path="/change-image" element={<ChangeImage />} />
        <Route path="/change-password" element={<ChangeMyPassword />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
      </Routes>
    );
  }
  return null;
}

export default RoutesComponent;
