import { Divider } from "@mui/material";
import Rating from "@mui/material/Rating";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Currency, Maya, TimeIcon } from "../../assets/images";
import { patchShiftRequest } from "../../redux/shiftSlice";
import { getImage } from "../../utils/getAwsFile";
import {
  getShiftTime,
  getShiftDate,
  getDateToFromString,
} from "../../utils/getShiftTime";
import { ConfirmDeleteModal } from "../confirmDeleteModal/confirmDeleteModal";
import { GiveEmployeeReviewModal } from "../giveEmployeeReviewModal/giveEmployeeReviewModal";
import { useCookies } from "react-cookie";

import "./shiftRequestCard.scss";

const ShiftRequestCard = ({ shiftData, picture: picture1, handleRefetch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cookies] = useCookies([]);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [action, setAction] = useState();
  const [reviewModalOpen, setReviewModalOpen] = useState(false);

  const shiftsArray = useSelector(
    (state) => state?.shiftSlice?.fetchShiftRequests?.res
  );
  const [picture, setPicture] = useState(null);

  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");

  const navigateEmployeeDetail = () => {
    const uuid = shiftData.employee;
    navigate(`/employee-details/${uuid}`);
  };

  function acceptHandler(newStatus) {
    dispatch(
      patchShiftRequest({
        uuid: shiftData.uuid,
        newStatus,
        dispatch,
        shiftsArray,
      })
    ).then(()=> {
      handleRefetch()
    })
  }

  function rejectHandler() {
    setConfirmDeleteModalOpen(true);
    setAction({
      name: "patchShiftRequest",
      handleRefetch: handleRefetch,
      body: {
        uuid: shiftData.uuid,
        newStatus: "Declined",
        dispatch,
        shiftsArray,
      },
    });
  }

  useEffect(() => {
    if (shiftData) {
      shiftData.files &&
        shiftData.files.length > 0 &&
        getImage(shiftData.files[0].aws_object_key).then((data) => {
          setPicture(data);
        });
    }
  }, [shiftData]);


  // if (picture)
  return (
    <>
      {reviewModalOpen && (
        <GiveEmployeeReviewModal
          setReviewModalOpen={setReviewModalOpen}
          shiftData={shiftData}
        />
      )}
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}
          action={action}
          message="Do you want to reject this shift request?"
        />
      )}
      <div className="request">
        <div className="request_container">
          <div className="content">
            <img
              src={picture1 ? "data:image/jpeg;base64," + picture1 : Maya}
              height="53px"
              width="53px"
              style={{ borderRadius: "4px" }}
              onClick={navigateEmployeeDetail}
              alt="James"
            />
            <div className="rating_container">
              <div className="person_name" onClick={navigateEmployeeDetail}>
                {`${shiftData["Employee Firstname"]} ${shiftData["Employee Lastname"]}`}
              </div>
              {cookies.token.user_type == "Agency" && (
                <div
                  style={{
                    color: "#2c3c55",
                    fontSize: "12px",
                    fontWeight: "600",
                    // textAlign: "center",
                    // alignSelf: "center",
                  }}
                >
                  ({shiftData.shift.facility_name})
                </div>
              )}
              <Rating
                name="half-rating"
                value={shiftData["Employee rating"]}
                precision={0.5}
                readOnly
                className="Rating"
              />
            </div>
          </div>
          <div className="duration">
            {timeAgo.format(new Date(shiftData.created_at))}
          </div>
        </div>

        <div className="request_timeline">
          <div className="request_timeline_section">
            <Currency />
            <div className="currency_container">
              <div className="rate">Rate per hour</div>
              <div className="money">
                {parseInt(shiftData?.shift?.rate_per_hour)}
              </div>
            </div>
          </div>

          <div className="request_timeline_section">
            <TimeIcon />
            <div className="currency_container">
              <div className="rate">Shift Time</div>
              <div className="money">
                {getShiftTime(
                  shiftData.shift.start_time,
                  shiftData.shift.end_time
                )}
              </div>
              <div
                className="money"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  alignSelf: "center",
                }}
              >
                {`(${getDateToFromString(
                  shiftData.shift.start_time,
                  shiftData.shift.end_time
                )})`}
                {/* {`(${getShiftDate(shiftData.shift.start_time, shiftData.shift.end_time)} to ${getShiftDate(
                  shiftData.shift.end_time
                )})`} */}
              </div>
            </div>
          </div>
        </div>

        {shiftData.status === "Pending" && (
          <>
            <Divider />
            <div className="request_buttons_container">
              <button className="view_details" onClick={navigateEmployeeDetail}>
                <span>View Details</span>
              </button>
              <div className="approval_container">
                <button
                  className="button accept "
                  onClick={() => acceptHandler("Accepted")}
                >
                  Accept
                </button>
                <button
                  className="button reject"
                  onClick={() => rejectHandler("Declined")}
                >
                  Reject
                </button>
              </div>
            </div>
          </>
        )}

        {shiftData.status === "Accepted" && (
          <>
            <Divider />
            <div className="request_buttons_container float-right">
              <div className="approval_container">
                <button
                  className="button cancel"
                  onClick={() => rejectHandler("Declined")}
                >
                  Cancel
                </button>
                <button
                  className="view_details"
                  onClick={navigateEmployeeDetail}
                >
                  <span>View Details</span>
                </button>
              </div>
            </div>
          </>
        )}
        {shiftData.status === "Completed" && (
          <>
            <Divider />
            <div className="request_buttons_container float-right">
              <div className="approval_container">
                <button
                  className="button give_review"
                  onClick={() => setReviewModalOpen(true)}
                >
                  Give Review
                </button>
                <button
                  className="view_details"
                  onClick={navigateEmployeeDetail}
                >
                  <span>View Details</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShiftRequestCard;
