import React from "react";
import { EmployeeDetails } from "../../../../components";
import {
  OfficeBag,
  House,
  License,
  Email,
  OpenEmail,
  Calendar,
  Phone,
  Refer,
  Clock,
  Home,
  Nurse,
} from "../../../../assets/images";
import "./employeeDetailsContainer.scss";
import { ImLocation2 } from "react-icons/im";
import dateFormat from "dateformat";

const EmployeeDetailsContainer = ({ employeeProfileSlice }) => {
  const parseAndClean = (data) => {
    let cleanedString = data.join("");
    cleanedString = cleanedString.replace(/[{}\[\]"]/g, "");
    return cleanedString;
  };

  return (
    <div className="personal-info">
      {/* <div className="d-flex content-gap" style={{ flexWrap: "wrap" }}> */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(224px, 1fr))",
          gap: "60px 80px",
        }}
      >
        {/* <div className="flex-column content"> */}
        <EmployeeDetails
          icon={<OfficeBag />}
          label="Date of Joining"
          info={dateFormat(
            employeeProfileSlice?.res?.profile?.user?.date_joined,
          )}
        />
        <EmployeeDetails
          icon={<Email />}
          label="Email"
          info={employeeProfileSlice?.res?.profile?.user?.email}
        />
        <EmployeeDetails
          icon={<Phone />}
          label="Contact Number"
          info={employeeProfileSlice?.res?.phone_number}
        />
        <EmployeeDetails
          icon={<Home />}
          label="State"
          info={employeeProfileSlice?.res?.address?.state}
        />

        <EmployeeDetails
          icon={<House />}
          label="City"
          info={employeeProfileSlice?.res?.address?.city}
        />
        <EmployeeDetails
          icon={<ImLocation2 color="#4c8bf5" />}
          label="Street. Address"
          info={`${employeeProfileSlice?.res?.address?.street1} ${employeeProfileSlice?.res?.address?.street2}`}
        />
        <EmployeeDetails
          icon={<OpenEmail />}
          label="Postal Code"
          info={employeeProfileSlice?.res?.address?.zipcode}
        />
        <EmployeeDetails
          icon={<Refer />}
          label="Refer"
          info={employeeProfileSlice?.res?.referred_by || "-"}
        />

        <EmployeeDetails
          icon={<License />}
          label="License Number"
          info={employeeProfileSlice?.res?.licence?.number || "-"}
        />
        <EmployeeDetails
          icon={<Calendar />}
          label="Expiry Date"
          info={dateFormatter(employeeProfileSlice?.res?.licence?.expiry)}
        />
        <EmployeeDetails
          icon={<Clock />}
          label="Preferred Shift"
          info={employeeProfileSlice?.res?.preferred_shift?.replace(
            " to ",
            "-",
          )}
        />

        <EmployeeDetails
          icon={<Nurse />}
          label="Position"
          info={parseAndClean(employeeProfileSlice?.res?.position)}
        />

        {/* </div> */}
        {/* <div className="flex-column content"> */}

        {/* </div> */}
        {/* <div className="flex-column content"> */}

        {/* </div> */}
        {/* <div className="flex-column content"> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default EmployeeDetailsContainer;

function dateFormatter(dateStr) {
  if (!dateStr) return;
  const date = new Date(dateStr);
  let options = { day: "2-digit", month: "2-digit", year: "numeric" };
  let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  formattedDate = formattedDate.replace(/\//g, "-");
  return formattedDate;
}
