export const sortGraphData = (
  { year: previousYear },
  pastMonth,
  { year: currentYear },
  currentMonth
) => {
  if (currentMonth.length > 0) {
    addMonthEntry(currentMonth, true);
    addYearEntry(currentMonth, currentYear);
    addMissingDaysCurrentMonth(currentMonth);
  }
  if (pastMonth.length > 0) {
    addMonthEntry(pastMonth);
    addYearEntry(pastMonth, previousYear);
    addMissingDays(pastMonth);
  }

  const combinedData = pastMonth.concat(currentMonth);

  // Define a comparator function
  function compareDates(a, b) {
    if (a.month < b.month) {
      return -1;
    } else if (a.month > b.month) {
      return 1;
    } else {
      return a.day - b.day;
    }
  }

  // Sort the data using the comparator function
  return combinedData.sort(compareDates).slice(-7);

  function addMonthEntry(data, isCurrentMonth) {
    const month = isCurrentMonth
      ? new Date().getMonth()
      : new Date().getMonth() - 1;
    for (let i = 0; i < data.length; i++) {
      data[i]["month"] = month;
    }
  }
  function addYearEntry(data, year) {
    data.map((entry) => (entry.year = year));
  }

  function addMissingDays(data) {
    const daysInMonth = new Date(data[0].year, data[0].month + 1, 0).getDate();

    for (let i = 1; i <= daysInMonth; i++) {
      const found = data.find((d) => d.day === i);

      if (!found) {
        data.push({ day: i, n: 0, month: data[0].month, year: data[0].year });
      }
    }

    return data.sort((a, b) => a.day - b.day);
  }
  function addMissingDaysCurrentMonth(data) {
    const daysInMonth = new Date().getDate();
    for (let i = 1; i <= daysInMonth; i++) {
      const found = data.find((d) => d.day === i);

      if (!found) {
        data.push({ day: i, n: 0, month: data[0].month, year: data[0].year });
      }
    }

    return data.sort((a, b) => a.day - b.day);
  }
};
