import React from "react";
import { PeopleRef } from "../../assets/images";
import "./referenceDetails.scss";

const ReferenceDetails = ({
  label,
  name,
  date,
  email,
  number,
  relation,
  known,
}) => {
  return (
    <div className="reference_details">
      <div className="reference_header align-center">
        <PeopleRef />
        <label>{label}</label>
      </div>
      <hr color="#C5D4EB" />
      <div className="ref_info" style={{ gap: "16px" }}>
        <div className="flex-column reference_content">
          <div>Name</div>
          <div>years known</div>
          <div>Email</div>
          <div>Number</div>
          <div>Relation</div>
        </div>
        <div className="flex-column reference_content_data">
          <div>{name}</div>
          <div>{known}</div>
          <div>{email}</div>
          <div>{number}</div>
          <div>{relation}</div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceDetails;
