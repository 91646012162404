export const formatDate = (date) =>
  new Date(date).toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    
  });

export const formatTime = (time) =>
  new Date(time).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

export const formatTimeDiff = (clock_out_time, clock_in_time) => {
  const timeDiff = new Date(new Date(clock_out_time) - new Date(clock_in_time));
  const diffHours = timeDiff.getUTCHours();
  const diffMinutes = timeDiff.getUTCMinutes();
  return diffHours ? `${diffHours} hours` : `${diffMinutes} minutes`;
};
