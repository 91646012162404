import React from "react";
import { LocationImg } from "../../../../assets/images";
import "./LocationsContainer.scss";

const LocationsContainer = () => {
  return (
    <div className="location-info">
      <img src={LocationImg} width="100%" />
    </div>
  );
};

export default LocationsContainer;
