import React, { useState } from "react";

import { useDispatch } from "react-redux";
import useCookies from "react-cookie/cjs/useCookies";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ProfileCard } from "../../components";

import { fetchTopRatedEmployee } from "../../redux/employeeSlice";
import { SideBarMenu, AppBar } from "../../components";
import { getImage } from "../../utils/getAwsFile";

export const ViewAllTopRated = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const [topRatedImages, setTopRatedImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    cookies?.token?.access && dispatch(fetchTopRatedEmployee());
  }, []);
  const topRatedEmployeeSubSlice = useSelector(
    (state) => state.employeeSlice.topRatedEmployees
  );

  useEffect(() => {
    if (topRatedEmployeeSubSlice.status !== "success") return;

    const imagePromises = topRatedEmployeeSubSlice.res
      .filter((emp) => emp.profile.is_active === true)
      .map((obj) =>
        obj.image_aws_object_key
          ? getImage(obj.image_aws_object_key)
          : Promise.resolve(null)
      );
    Promise.all(imagePromises).then((images) => {
      setTopRatedImages(images);
      setLoading(false);
    });
  }, [topRatedEmployeeSubSlice.res]);

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          {loading ? (
            <div style={{ marginTop: 64 }} className="loader margin"></div>
          ) : (
            <>
              <section>
                <div className="homepage_content-header">
                  <h1>Top Rated</h1>
                </div>
                <div className="homepage_content-box">
                  {topRatedEmployeeSubSlice.status === "success" &&
                    topRatedEmployeeSubSlice?.res
                      ?.filter((emp) => emp.profile.is_active === true)
                      ?.map((topRatedEmployee, index) => (
                        <ProfileCard
                          img={topRatedEmployee.image_aws_object_key}
                          key={topRatedEmployee.id}
                          employee={topRatedEmployee}
                        />
                      ))}
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
