import React, { useState } from "react";
import "./toggleBtn.scss";
import { AdminIcon, OfficeBuildingIcon } from "../../assets/images";

const ToggleButton = ({
  activeLoginUserType,
  setActiveLoginUserType,
  setEmail,
  setPassword,
}) => {
  const clickHandler = (newActiveUserType) => {
    setActiveLoginUserType(newActiveUserType);
    setEmail("");
    setPassword("");
  };
  return (
    <div className="toggle_btn align-center justify-center">
      <div className="d-flex align-center justify-center ">
        <button
          className={`d-flex toggle_btn_container ${
            activeLoginUserType === "Agency" && "active"
          } align-center justify-center`}
          onClick={() => clickHandler("Agency")}
        >
          <AdminIcon className="toggle_btn_icon" />
          <span className={`toggle_btn_container_text`}>Agency</span>
        </button>
        <button
          className={`d-flex ${
            activeLoginUserType === "Facility" && "active"
          } toggle_btn_container align-center justify-center`}
          onClick={() => clickHandler("Facility")}
        >
          <OfficeBuildingIcon className="toggle_btn_icon" />
          <span className="toggle_btn_container_text">Facility</span>
        </button>
      </div>
    </div>
  );
};

export default ToggleButton;
