import React, { useState } from "react";
import { useEffect } from "react";
import "./SplashCarousel.scss";

const SplashCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((previousActiveIndex) =>
        previousActiveIndex == 2 ? 0 : previousActiveIndex + 1
      );
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const items = [
    {
      title: "Staffing simplified",
      body: "Transparent, reliable staffing, at a fraction of the cost",
    },
    {
      title: "Innovation at your fingertips",
      body: "Stand out in the industry and maximize your profits",
    },
    {
      title: "Innovation",
      body: "Stand out in the industry",
    },
  ];
  return (
    <div className="splash-carousel">
      <div className="carousel-item">
      <div className="carousel-text-title" style={{ marginBottom: "12px", "::selection": { backgroundColor: "green", color: "#fff" } }}>
          {items[activeIndex].title}
        </div>
        <div className="carousel-text-body" style={{ marginBottom: "12px" }}>
          {items[activeIndex].body}
        </div>
      </div>
      <div className="carousel-dots">
        <button
          className={`carousel-button ${
            activeIndex === 0 && "active-carousel-button"
          }`}
          onClick={() => setActiveIndex(0)}
        ></button>
        <button
          className={`carousel-button ${
            activeIndex === 1 && "active-carousel-button"
          }`}
          onClick={() => setActiveIndex(1)}
        ></button>
        <button
          className={`carousel-button ${
            activeIndex === 2 && "active-carousel-button"
          }`}
          onClick={() => setActiveIndex(2)}
        ></button>
      </div>
    </div>
  );
};

export default SplashCarousel;
