import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// FOR FETCHING COMPLETED SHIFTS
export const fetchCreatedShifts = createAsyncThunk(
  "Shift/fetchCreatedShifts",
  async ({ fromDate, toDate }, thunk) => {
    // if (thunk.getState().shiftSlice.fetchShiftRequests?.res)
    //   return thunk.getState().shiftSlice.fetchShiftRequests.res;

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts?${!!fromDate ? `from_date=${fromDate}` : ""}${!!fromDate ? "&" : ""}${!!toDate ? `to_date=${toDate}` : ""}`,
    );
    return response.data;
  },
);
// FOR FETCHING SHIFTS
export const FetchShiftRequests = createAsyncThunk(
  "Shift/FetchShiftRequests",
  async ({ uuid, pageLimit, page, pagination, status }, thunk) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/${uuid}/requests?${!!pageLimit ? `batch=${pageLimit}` : ""}${!!page ? `&page=${page}` : ""}${!!pagination ? `&pagination=${pagination}` : ""}${!!status ? `&status=${status}` : ""}`,
    );

    return { type: status, ...response.data };
  },
);
// FOR FETCHING COMPLETED SHIFTS
export const FetchCompletedShifts = createAsyncThunk(
  "Shift/FetchCompletedShifts",
  async ({ uuid }, thunk) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/${uuid}/requests`,
      {
        params: {
          status: "Completed",
        },
      },
    );
    return response.data.reverse();
  },
);

// FOR FETCHING CALENDAR
export const fetchCalendar = createAsyncThunk(
  "Shift/fetchCalendar",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/calendar`,
    );
    return response.data;
  },
);

// FOR PATCHING/UPDATING SHIFT REQUEST i.e FROM PENDING TO APPROVED etc
export const patchShiftRequest = createAsyncThunk(
  "Shift/patchShiftRequest",
  async ({ uuid, newStatus, dispatch, shiftsArray }) => {
    const updatedShift = await axios.patch(
      `${process.env.REACT_APP_API_URL}/shifts/requests/${uuid}`,
      {
        status: newStatus,
      },
    );
    const updatedShiftsArray = shiftsArray?.map((shiftrequest) =>
      shiftrequest.uuid == updatedShift.data.uuid
        ? updatedShift.data
        : shiftrequest,
    );
    dispatch(updateShiftRequests(updatedShiftsArray));
    return updatedShift.data;
  },
);

// FOR CREATING A NEW SHIFT
export const createShiftCall = createAsyncThunk("createShift", async (body) => {
  const shiftResponse = await axios.post(
    `${process.env.REACT_APP_API_URL}/shifts/create`,
    {
      ...body,
    },
  );
  if (!body?.employee) return shiftResponse.data;
  const assignResponse = await axios.post(
    `${process.env.REACT_APP_API_URL}/shifts/assign`,
    {
      shift: shiftResponse.data.uuid,
      employee: body.employee,
    },
  );
  return assignResponse.data;
});

// FOR CREATING A NEW SHIFT
export const fetchAllTimesheet = createAsyncThunk(
  "shift/fetchAllTimesheet",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/timesheets`,
    );

    return response.data;
  },
);

//  FOR DELETING A SHIFT
export const deleteShift = createAsyncThunk(
  "shift/deleteShift",
  async (uuid, thunkAPI) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/shifts/${uuid}`,
      );
      // thunkAPI.dispatch(fetchCreatedShifts());
      return res.data;
    } catch (err) {
      console.error("err 💣: ", err);
    }
  },
);

// FOR Giving EMPLOYEE REVIEW ON SHIFT
export const postEmployeeReview = createAsyncThunk(
  "shift/postEmployeeReview",
  async (
    {
      employeeUUID,
      ShiftUUID,
      rating,
      textFeedBack,
      patient_care,
      professionalism,
      attendance,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employees/${employeeUUID}/reviews/create`,
        {
          // rating: rating,
          feedback: textFeedBack,
          is_anonymous: false,
          shift: ShiftUUID,
          patient_care,
          professionalism,
          attendance,
        },
      );
      return response.data;
    } catch (err) {
      if (err.request.status === 400)
        return rejectWithValue(
          "You have already given a review for this shift",
        );
    }
  },
);

const initialState = {
  fetchShiftRequests: { status: "initial state", res: null, error: null },
  patchShiftRequest: { status: "initial state", res: {}, error: null },
  createShift: { status: "initial state", res: {}, error: null },
  calendar: { status: "initial state", res: {}, error: null },
  allTimeSheet: { status: "initial state", res: {}, error: null },
  completedShifts: { status: "initial state", res: {}, error: null },
  createdShifts: { status: "initial state", res: {}, error: null },
};

const shiftSlice = createSlice({
  name: "shiftRequestsSlice",
  initialState,
  reducers: {
    clearCreateShiftRequest: (state, action) => {
      state.createShift = { status: "initial state", res: {}, error: null };
    },

    updateShiftRequests: (state, action) => {
      state.fetchShiftRequests.res = action.payload;
    },
  },
  extraReducers: {
    // FetchShiftRequests

    [FetchShiftRequests.pending]: (state) => {
      state.fetchShiftRequests.status = "loading";
    },
    [FetchShiftRequests.fulfilled]: (state, action) => {
      state.fetchShiftRequests.status = "success";
      state.fetchShiftRequests.res = action.payload;
      state.fetchShiftRequests.error = null;
    },
    [FetchShiftRequests.rejected]: (state, action) => {
      state.fetchShiftRequests.status = "failed";
      state.fetchShiftRequests.res = {};
      state.fetchShiftRequests.error = action.error;
    },

    // FetchCompletedShifts

    [FetchCompletedShifts.pending]: (state) => {
      state.completedShifts.status = "loading";
    },
    [FetchCompletedShifts.fulfilled]: (state, action) => {
      state.completedShifts.status = "success";
      state.completedShifts.res = action.payload;
      state.completedShifts.error = null;
    },
    [FetchCompletedShifts.rejected]: (state, action) => {
      state.completedShifts.status = "failed";
      state.completedShifts.res = {};
      state.completedShifts.error = action.error;
    },

    // patchShiftRequest

    [patchShiftRequest.pending]: (state) => {
      state.patchShiftRequest.status = "loading";
    },
    [patchShiftRequest.fulfilled]: (state, action) => {
      state.patchShiftRequest.status = "success";
      state.patchShiftRequest.res = action.payload;

      state.patchShiftRequest.error = null;
    },
    [patchShiftRequest.rejected]: (state, action) => {
      state.patchShiftRequest.status = "failed";
      state.patchShiftRequest.res = {};
      state.patchShiftRequest.error = action.error;
    },

    // createShiftCall

    [createShiftCall.pending]: (state) => {
      state.createShift.status = "loading";
    },
    [createShiftCall.fulfilled]: (state, action) => {
      state.createShift.status = "success";
      state.createShift.res = action.payload;
      state.createShift.error = null;
    },
    [createShiftCall.rejected]: (state, action) => {
      state.createShift.status = "failed";
      state.createShift.res = {};
      state.createShift.error = action.error;
    },

    // fetchCalendar
    [fetchCalendar.pending]: (state) => {
      state.calendar.status = "loading";
    },
    [fetchCalendar.fulfilled]: (state, action) => {
      state.calendar.status = "success";
      state.calendar.res = action.payload;
      state.calendar.error = null;
    },
    [fetchCalendar.rejected]: (state, action) => {
      state.calendar.status = "failed";
      state.calendar.res = {};
      state.calendar.error = action.error;
    },

    // fetch Timesheet
    [fetchAllTimesheet.pending]: (state) => {
      state.allTimeSheet.status = "loading";
    },
    [fetchAllTimesheet.fulfilled]: (state, action) => {
      state.allTimeSheet.status = "success";
      state.allTimeSheet.res = action.payload;
      state.allTimeSheet.error = null;
    },
    [fetchAllTimesheet.rejected]: (state, action) => {
      state.allTimeSheet.status = "failed";
      state.allTimeSheet.res = {};
      state.allTimeSheet.error = action.error;
    },

    // fetch fetchCreatedShifts
    [fetchCreatedShifts.pending]: (state) => {
      state.createdShifts.status = "loading";
    },
    [fetchCreatedShifts.fulfilled]: (state, action) => {
      state.createdShifts.status = "success";
      state.createdShifts.res = action.payload;
      state.createdShifts.error = null;
    },
    [fetchCreatedShifts.rejected]: (state, action) => {
      state.createdShifts.status = "failed";
      state.createdShifts.res = {};
      state.createdShifts.error = action.error;
    },
  },
});

export const { updateShiftRequests, clearCreateShiftRequest } =
  shiftSlice.actions;

export default shiftSlice.reducer;
