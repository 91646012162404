import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import { ShiftRequestCard } from "../../components";
import "./ShiftCardsContainer.scss";
import { getParameterValueFromUrl } from "../../utils/getQueryParamsValue";
const ShiftCardsContainer = ({
  handleRefetch,
  type,
  uuid,
  shiftCards,
  handlePaginationChangeDispatch,
  picutesArray,
  pageLimit,
  pagination,
}) => {
  const { status, data } = shiftCards;
  const count = data?.count ? Math.ceil(data.count / pageLimit) : 0;
  const [page, setPage] = useState(pageCalculator(data, pageLimit));

  const handlePaginationChange = (_, value) => {
    setPage(value);
    if (uuid && pageLimit && pagination && value && type) {
      handlePaginationChangeDispatch({ page: value, status: type });
    }
  };

  if (status === "loading") {
    return (
      <div
        style={{ gridColumn: "1 / max", marginTop: "64px" }}
        className="loader margin"
      ></div>
    );
  }

  return (
    <>
      <div className="shifts_card_wrapper">
        {data.count ? (
          data.results.map((shiftData, index) => (
            <ShiftRequestCard
              shiftData={shiftData}
              key={shiftData.uuid}
              picture={picutesArray[index]}
              handleRefetch={handleRefetch}
            />
          ))
        ) : (
          <div className="no_shifts_wrapper">No Shifts Found</div>
        )}
      </div>

      {count ? (
        <div className="pagination_wrapper">
          <Pagination
            page={page}
            count={count}
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ShiftCardsContainer;


const pageCalculator = (data, pageLimit) => {
  const { previous, next, count } = data;
  if (count == 0) { // no page 
    return 0;
  } else if (count <= pageLimit || (!next && !previous)) { // single page  
    return 1;
  } else { // for multiple pages
    if (next) { 
      const pageValue = getParameterValueFromUrl(next, "page");
      return pageValue - 1;
    } else { // if there's no next ->  last page 
      return Math.ceil(count / pageLimit);
    }
  }
};
