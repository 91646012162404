import React, { useEffect, useState } from "react";
import { BackIcon, NotificationHomePageIcon } from "../../assets/images";

import "./notificationHomePage.scss";
import { AppBar, SideBarMenu } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotification } from "../../redux/notificationsSlice";
import { GiveEmployeeReviewModal } from "../../components/giveEmployeeReviewModal/giveEmployeeReviewModal";

import { TrueCheck } from "../../assets/images";

function formatTime(dateString) {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime;
}

const NotificationHomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reviewsModalOpen, setReviewsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchNotification());
  }, []);

  const notificationsSubSlice = useSelector(
    (state) => state?.notificationsSlice
  );

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="notifiation_home_page">
            <div className="notifiation_home_page_header">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
              >
                <BackIcon />
                <span style={{ marginLeft: "12.5px" }}>Notifications</span>
              </div>
            </div>
            {Array.isArray(notificationsSubSlice.res) ? (
              notificationsSubSlice.res.length > 0 ? (
                notificationsSubSlice.res.map((notification) => (
                  <>
                    {reviewsModalOpen && (
                      <GiveEmployeeReviewModal
                        setReviewsModalOpen={setReviewsModalOpen}
                      />
                    )}

                    <div
                      className="notification_card_container"
                      onClick={() => setReviewsModalOpen(true)}
                    >
                      <div className="notification_card">
                        <NotificationHomePageIcon />
                        <div className="notification_card_content">
                          {notification.message}
                        </div>
                      </div>
                      <div className="time">
                        {formatTime(notification.created_at)}
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="notification_card_container">
                  <div className="notification_card">
                    <TrueCheck />
                    <div className="notification_card_content">
                      There are no notifications at this time.
                      {/* You're all caught
                      up! */}
                    </div>
                  </div>
                  <div className="time">{/* 24:00 */}</div>
                </div>
              )
            ) : (
              <div style={{ marginTop: 64 }} className="loader margin"></div>
            )}

            {/* {[...Array(8).keys()].map((e) => (
              <div className="notification_card_container">
                <div className="notification_card">
                  <NotificationHomePageIcon />
                  <div className="notification_card_content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    Aenean sit.Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit Aenean sit. Lorem ipsum. Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit Aenean sit
                  </div>
                </div>
                <div className="time">10:35 PM</div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationHomePage;
