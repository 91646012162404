import { Link } from "react-router-dom";
import "./requestCard.scss";

const RequestCard = ({ name, count, requestLogo, link }) => {
  return (
    <div className="request-card">
      <div className="request-container">
        {requestLogo}
        <div className="request-label">{name}</div>
      </div>

      <div className="display-container">
        <div className="request-count">{count}</div>
        <div className="request-view-btn cursor-pointer">
          <Link to={link} className="text-deco-none">
            <span>View All</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
