import React, { useState } from "react";

import "./ChangeEmailModal.scss";
import axios from "axios";

const ChangeEmailModal = ({ setPopup }) => {
  const [newEmail, setNewEmail] = useState("");
  const [repeatNewEmail, setRepeatNewEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (newEmail !== repeatNewEmail) {
      setError("Both emails doesn't match.");
      return;
    }
    try {
      const res = await axios.post(
        "https://backend-v2.thenursebridge.com/api/users/email_change",
        {
          new_email: newEmail,
          confirm_email: newEmail,
        }
      );
      window.location.reload();
    } catch (err) {
      setError(err.response.data.message);
    }
  };

  return (
    <div
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setPopup(false);
        }
      }}
      className="emailModal__container"
    >
      <div className="emailModal">
        <h2>Change Your Email</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <span>Email</span>
            <input
              required
              placeholder="Enter Email"
              type="email"
              onChange={(e) => setNewEmail(e.target.value)}
              value={newEmail}
            />
          </label>
          <label>
            <span>Repeat Email</span>
            <input
              required
              placeholder="Repeat Email"
              type="email"
              onChange={(e) => setRepeatNewEmail(e.target.value)}
              value={repeatNewEmail}
            />
          </label>
          {error && (
            <p
              style={{
                color: "red",
                fontSize: 16,
              }}
            >
              {error}
            </p>
          )}
          <button type="submit">Change Email</button>
        </form>
      </div>
    </div>
  );
};

export default ChangeEmailModal;
