import { AppBar, ProfileSettingsBtn, SideBarMenu } from "../../components";
import {
  NotificationDetailsIcon,
  ChevronRight,
  LogoutIcon,
  AccountDetailsIcon,
  MayoClinic,
  Camera,
  ChevronLeft,
  HospitalFacility,
  FacilityBuilding,
  FacilityEmail,
  FacilityLocation,
} from "../../assets/images";

import { useDispatch } from "react-redux";

import { fetchFacilityProfile } from "../../redux/facilitySlice";

import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import "./FacilityDetails.scss";

import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";

import { getImage } from "../../utils/getAwsFile";

const FacilityDetails = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [mainImage, setMainImage] = useState(HospitalFacility);

  const [facilityName, setFacilityName] = useState();
  const [facilitEmail, setFacilitEmail] = useState();
  const [facilityAddress, setFacilityAddress] = useState();
  const [facilityDescription, setFacilityDescription] = useState();
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchFacilityProfile(uuid));
  }, []);

  const facilityProfileSubSlice = useSelector(
    (state) => state.facilitySlice.profile
  );

  useEffect(() => {
    if (pictures.length > 0) return;
    setPictures([]);
    if (facilityProfileSubSlice?.res?.files) {
      Promise.all(
        facilityProfileSubSlice?.res?.files.map((fileObj) =>
          getImage(fileObj.aws_object_key)
        )
      ).then((data) => {
        setPictures(data);
        setLoading(false);
      });
    }
    if (
      facilityProfileSubSlice?.status == "success" &&
      facilityProfileSubSlice?.res?.files?.length == "0"
    )
      setLoading(false);
  }, [facilityProfileSubSlice]);

  useEffect(() => {
    setFacilityName(facilityProfileSubSlice?.res?.profile?.user?.username);
    setFacilitEmail(facilityProfileSubSlice?.res?.profile?.user?.email);
    setFacilityDescription(facilityProfileSubSlice?.res?.description);

    let address = facilityProfileSubSlice?.res?.address;
    address =
      address?.street1 +
      " " +
      address?.street2 +
      address?.city +
      "," +
      address?.state;
    setFacilityAddress(address);
  }, [facilityProfileSubSlice]);

  return (
    <>
      <div className="homepage">
        <SideBarMenu />
        <div className="homepage_container">
          <AppBar />
          {!loading ? (
            <div className="facility-profile-container">
              <header>
                <div
                  onClick={() => navigate(-1)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ChevronLeft />
                  <span style={{ marginLeft: "12px" }}>Profile</span>
                </div>
              </header>
              {pictures.length > 0 && (
                <section className="images-section">
                  <div
                    style={{
                      maxWidth: "100%",
                      minWidth: "100%",
                      // maxHeight: "516px",
                      // overflow: "hidden",
                    }}
                  >
                    <Carousel
                      interval="10000"
                      navButtonsAlwaysVisible="true"
                      height="414px"
                    >
                      {pictures.map((picture, index) => (
                        <>
                          <p style={{ textAlign: "center" }} key={index}>
                            <img
                              src={"data:image/png;base64," + picture}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </p>
                        </>
                      ))}
                    </Carousel>
                  </div>
                </section>
              )}

              {/* <section className="images-section">
              <img
                src={mainImage}
                className="main-img"
                alt="facility selected img"
              />
              <div className="secondary-imgs">
                {pictures.map((pic) => {
                  return (
                    <img
                      src={"data:image/jpeg;base64," + pic}
                      alt="facility-img"
                      className="secondary-img cursor-pointer"
                      onClick={(e) => setMainImage(e.target.currentSrc)}
                    />
                  );
                })}
              </div>
            </section> */}
              <h2>Details</h2>
              <section className="facility-detail-section">
                <div className="top-bar">
                  <div className="facility-detail">
                    <div className="icon-wrapper">
                      <FacilityBuilding />
                    </div>
                    <div className="facility-detail-text">
                      <div className="title">Full Name</div>
                      <div className="details">{facilityName}</div>
                    </div>
                  </div>
                  <div className="facility-detail">
                    <div className="icon-wrapper">
                      <FacilityEmail />
                    </div>
                    <div className="facility-detail-text">
                      <div className="title">Email</div>
                      <div className="details">{facilitEmail}</div>
                    </div>
                  </div>
                  <div className="facility-detail">
                    <div className="icon-wrapper">
                      <FacilityLocation />
                    </div>
                    <div className="facility-detail-text">
                      <div className="title">Location</div>
                      <div className="details">{facilityAddress}</div>
                    </div>
                  </div>
                </div>
                <h3 className="bold-text"> Description </h3>
                <p className="description-text">{facilityDescription}</p>
              </section>
            </div>
          ) : (
            <div style={{ marginTop: 64 }} className="loader margin"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default FacilityDetails;
