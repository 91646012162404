import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  application: "Employee",
  requestBtn: "Pending",
};

const tempStorageSlice = createSlice({
  name: "tempStorageSlice",
  initialState,
  reducers: {
    setApplication: (state, action) => {
      state.application = action.payload;
    },
    setRequestBtn: (state, action) => {
      state.requestBtn = action.payload;
    },
  },
});

export const { setApplication, setRequestBtn } = tempStorageSlice.actions;
export default tempStorageSlice.reducer;
