import { useCallback, useEffect, useState } from "react";
import { Plus } from "../../assets/images";
import { AppBar, ShiftRequestCard, SideBarMenu } from "../../components";
import Pagination from "@mui/material/Pagination";

import "./requestsContainer.scss";

import { FetchShiftRequests } from "../../redux/shiftSlice";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import CreateNewShiftModal1 from "../../components/createNewShiftModalDatenTime/CreateNewShiftModal1";
import CreateNewShiftModal2 from "../../components/createNewShiftModal2/CreateNewShiftModal2";
import { getImage } from "../../utils/getAwsFile";
import ShiftCardsContainer from "../../components/ShiftCardsContainer/ShiftCardsContainer";

export const pageLimit = 6;
const pagination = true;

const RequestsContainer = () => {
  const dispatch = useDispatch();

  const uuid = useSelector(
    (state) => state.userInfoSlice?.res?.profile?.user?.uuid
  );

  const shiftRequestsSubSlice = useSelector(
    (state) => state.shiftSlice.fetchShiftRequests
  );

  const [activeBtn, setActiveBtn] = useState("Pending");
  const [picutesArray, setPicturesArray] = useState([]);
  const [imagesLodaing, setImagesLoading] = useState(true);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal1Values, setModal1Values] = useState();


  const [filteredRequest, setFilteredRequest] = useState({
    Accepted: { status: "initial_state", data: {} },
    Pending: { status: "initial_state", data: {} },
    Declined: { status: "initial_state", data: {} },
    Completed: { status: "initial_state", data: {} },
  });

  const handleRefetch = ()=> {
    if (uuid) {
      dispatch(
        FetchShiftRequests({ uuid, pageLimit, pagination, status: activeBtn })
      );
      setFilteredRequest((prev) => {
        return {
          ...prev,
          [activeBtn]: {
            ...prev[activeBtn],
            status: "loading",
          },
        };
      });
    }
  }

  useEffect(() => {
  handleRefetch()
  }, [uuid, activeBtn]);

  useEffect(() => {
    setImagesLoading(true);
    if (
      filteredRequest[activeBtn]?.data?.results?.length === 0 ||
      !activeBtn ||
      !Boolean(filteredRequest[activeBtn]?.data?.results)
    ) {
      setImagesLoading(false);
      return;
    }
    Promise.all(
      filteredRequest[activeBtn]?.data?.results?.map((requestData) =>
        requestData.files[0]?.aws_object_key
          ? getImage(requestData.files[0]?.aws_object_key)
          : Promise.resolve(null)
      )
    )
      .then((imageDataArray) => {
        setPicturesArray(imageDataArray);
        setImagesLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [filteredRequest[activeBtn]]);

  useEffect(() => {
    if (shiftRequestsSubSlice.status == "success") {
      let tempFiltered = {};
      const status = shiftRequestsSubSlice?.res?.type;
      if (status) {
        tempFiltered[status] = { data: shiftRequestsSubSlice?.res };
        tempFiltered[status].status = "success";
      }
      setFilteredRequest({ ...filteredRequest, ...tempFiltered });
    }
  }, [shiftRequestsSubSlice]);

  const handlePaginationChangeDispatch = (data) => {
    const { page, status } = data;
    dispatch(FetchShiftRequests({ uuid, pagination, pageLimit, page, status }));
    setFilteredRequest((prev) => {
      return {
        ...prev,
        [status]: {
          ...prev[status],
          status: "loading",
        },
      };
    });
  };

  return (
    <>
      {modal1Open && (
        <CreateNewShiftModal1
          modal1Values={modal1Values}
          setModal1Values={setModal1Values}
          setModal1Open={setModal1Open}
          setModal2Open={setModal2Open}
        />
      )}
      {modal2Open && (
        <CreateNewShiftModal2
          modal1Values={modal1Values}
          setModal1Open={setModal1Open}
          setModal1Values={setModal1Values}
          setModal2Open={setModal2Open}
        />
      )}
      <div className="homepage">
        <SideBarMenu />
        <div className="homepage_container">
          <AppBar />
          <div className="homepage_content">
            <div className="user_request">
              <div className="user_request_container">
                <div className="request_section">
                  <div
                    style={{ cursor: "pointer" }}
                    className={`request_text ${
                      activeBtn === "Pending" && "active"
                    }`}
                    onClick={() => {
                      if (activeBtn === "Pending") return;
                      setImagesLoading(true);
                      setActiveBtn("Pending");
                    }}
                  >
                    Shift Requests
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={`request_text ${
                      activeBtn === "Accepted" && "active"
                    }`}
                    onClick={() => {
                      if (activeBtn === "Accepted") return;
                      setImagesLoading(true);
                      setActiveBtn("Accepted");
                    }}
                  >
                    Approved Requests
                  </div>
                  <div
                    className={`request_text cursor-pointer ${
                      activeBtn === "Declined" && "active"
                    }`}
                    onClick={() => {
                      if (activeBtn === "Declined") return;
                      setImagesLoading(true);
                      setActiveBtn("Declined");
                    }}
                  >
                    Cancelled Requests
                  </div>
                  <div
                    className={`request_text cursor-pointer ${
                      activeBtn === "Completed" && "active"
                    }`}
                    onClick={() => {
                      if (activeBtn === "Completed") return;
                      setImagesLoading(true);
                      setActiveBtn("Completed");
                    }}
                  >
                    Completed Shifts
                  </div>
                </div>
                <div
                  className="new_shift cursor-pointer"
                  onClick={() => setModal1Open(true)}
                >
                  <Plus />
                  <span>Create New Shift</span>
                </div>
              </div>
              {false ? (
                <div
                  style={{ gridColumn: "1 / max", marginTop: "64px" }}
                  className="loader margin"
                ></div>
              ) : (
                <ShiftCardsContainer
                  handleRefetch={handleRefetch}
                  key={activeBtn}
                  uuid={uuid}
                  type={activeBtn}
                  shiftCards={filteredRequest[activeBtn]}
                  handlePaginationChangeDispatch={
                    handlePaginationChangeDispatch
                  }
                  picutesArray={picutesArray}
                  pageLimit={pageLimit}
                  pagination={pagination}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestsContainer;
