import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ModalCloseIcon } from "../../assets/images";

import { RadioGroup } from "@mui/material";
import MyFormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import { patchAgencyPermission } from "../../redux/agencySlice";

import "../createAgencyModal/createAgencyModal.scss";
import "./editAgencyModal.scss";

export const EditAgencyModal = ({ uuid, setEditAgencyModalOpen }) => {
  const [approve, setApprove] = useState();
  const [del, setDel] = useState();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [isEditDisabled, setIsEditDisabled] = useState(false);

  const permissionsArray = [approve ? "AE" : false, del ? "DE" : false].filter(
    (item) => item !== false && item !== undefined
  );

  const addAgencyClickHandler = (e) => {
    setError("");
    if (!approve && !del) return setError("Please allow at least one permission");
    setIsEditDisabled(true);
    dispatch(patchAgencyPermission({ uuid, permissionsArray })).then((data) => {
      setIsEditDisabled(false);
      data.meta.requestStatus == "fulfilled" && setSuccess(true);
    });
  };

  if (success)
    return (
      <div className="edit-agency-modal-wrapper">
        <div
          className="edit-agency-modal"
          style={{
            width: "728px",
            background: "#ffffff",
            borderRadius: "4px",
            padding: "16px 24px",
            position: "relative",
          }}
        >
          <ModalCloseIcon
            onClick={() => setEditAgencyModalOpen(false)}
            style={{
              cursor: "pointer",
              // left: "100%",
              // transform: "translate(-100%)",
              position: "absolute",
              top: "12px",
              right: "24px",
              zIndex: "1000",
            }}
          />
          <div
            style={{
              color: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "150px",
              height: "150px",
              fontWeight: 500,
              fontSize: "20px",
            }}
          >
            permissions changed successfully
          </div>
        </div>
      </div>
    );

  return (
    <div className="edit-agency-modal-wrapper">
      <div
        className="edit-agency-modal"
        style={{
          width: "728px",
          background: "#ffffff",
          borderRadius: "4px",
          padding: "16px 24px",
          position: "relative",
        }}
      >
        <div className="roles-permissions" style={{ marginBottom: "1rem" }}>
          Roles & Permissions
        </div>
        <ModalCloseIcon
          onClick={() => setEditAgencyModalOpen(false)}
          style={{
            cursor: "pointer",
            // left: "100%",
            // transform: "translate(-100%)",
            position: "absolute",
            top: "12px",
            right: "24px",
            zIndex: "1000",
          }}
        />

        <>
          <div className="multi-choice">
            <div className="agency-multiple-qs">Can approve employees</div>
            <div>
              <RadioGroup name="radio-buttons-group" style={{ flexDirection: "row" }}>
                <MyFormControlLabel
                  value="first"
                  label="Yes"
                  control={<Radio />}
                  className="multi-text"
                  onClick={() => setApprove(true)}
                />
                <MyFormControlLabel
                  value="second"
                  label="No"
                  control={<Radio />}
                  className="multi-text"
                  onClick={() => setApprove(false)}
                />
              </RadioGroup>
            </div>
          </div>
          <div className="multi-choice">
            <div className="agency-multiple-qs">Can delete employees</div>
            <div>
              <RadioGroup name="radio-buttons-group" style={{ flexDirection: "row" }}>
                <MyFormControlLabel
                  value="first"
                  label="Yes"
                  control={<Radio />}
                  className="multi-text"
                  onClick={() => setDel(true)}
                />
                <MyFormControlLabel
                  value="second"
                  label="No"
                  control={<Radio />}
                  className="multi-text"
                  onClick={() => setDel(false)}
                />
              </RadioGroup>
            </div>
          </div>
        </>
        <div className="error-div" style={{ color: "red", fontSize: "14px", textAlign: "center" }}>
          {error}
        </div>

        <button
          className="add-agency-btn"
          style={{ cursor: "pointer", border: "none" }}
          onClick={() => addAgencyClickHandler()}
          // disabled={true}
          disabled={isEditDisabled}
        >
          <span>{isEditDisabled ? "Editing Agency..." : "Edit Agency"}</span>
        </button>
      </div>
    </div>
  );
};
