import { AppBar, SideBarMenu } from "../../components";

import {
  PlusIcon,
  SeenIcon,
  BlockIcon,
  TableFieldImg,
} from "../../assets/images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { deleteAgency } from "../../redux/agencySlice";
import { ConfirmDeleteModal } from "../../components/confirmDeleteModal/confirmDeleteModal";

import "./agencyListContainer.scss";
import { useDispatch } from "react-redux";
import { fetchAgenciesList } from "../../redux/agencySlice";
import { useSelector } from "react-redux";
import { useState } from "react";
import { CreateAgencyModal } from "../../components";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { EditAgencyModal } from "../../components/editAgencyModal/editAgencyModal";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";

import { FiEdit } from "react-icons/fi";
import { deleteFacility } from "../../redux/facilitySlice";

const AgencyListContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //MODAL FOR CREATING NEW AGENCY
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteAgencyModalOpen, setDeleteAgencyModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [deleteAction, setDeleteAction] = useState();

  function createData(tableFieldImgRow, email, permissions, actionIcons) {
    return { tableFieldImgRow, email, permissions, actionIcons };
  }

  useEffect(() => {
    dispatch(fetchAgenciesList());
  }, []);

  const agenciesListSubSlice = useSelector(
    (state) => state.agencySlice.agenciesList
  );

  // IF "agenciesListSubSlice.res" IS ARRAY, MAP IT'S ELEMENTS TO ROWS OTHERWISE JSUT STORE AN EMPTY ARRAY IN ROWS
  const rows = Array.isArray(agenciesListSubSlice.res)
    ? agenciesListSubSlice.res.map((agency) => {
        // SETTING PERMISSION OF AGENCY
        let permissions;
        if (
          agency.permissions.includes("AE") &&
          agency.permissions.includes("DE")
        )
          permissions = "Approve & Delete Employees";
        else if (agency.permissions.includes("AE"))
          permissions = "Approve Employees";
        else if (agency.permissions.includes("DE"))
          permissions = "Delete Employees";
        else permissions = "-";

        return createData(
          <div className="table-field-img-row">
            <TableFieldImg />
            <div>{agency.name}</div>
          </div>,
          agency.profile.user.email,
          permissions,
          <div className="action-icons">
            <Link to={`/agency-details/${agency.profile.user.uuid}`}>
              <SeenIcon />
            </Link>

            <FiEdit
              className="cursor-pointer"
              style={{
                width: "24px",
                height: "24px",
                background: "rgba(0, 128, 0, 0.1)",
                padding: "4.6px 4px",
                borderRadius: "4px",
              }}
              onClick={() => {
                navigate(`/agency-settings/${agency.profile.user.uuid}`);
              }}
            />
            <BlockIcon
              className="cursor-pointer"
              onClick={() => {
                setDeleteAgencyModalOpen(true);
                setDeleteAction({
                  name: "deleteAgency",
                  body: agency.profile.user.uuid,
                });
              }}
            />
          </div>
        );
      })
    : [];

  return (
    <div className="homepage">
      {deleteAgencyModalOpen && (
        <ConfirmDeleteModal
          setConfirmDeleteModalOpen={setDeleteAgencyModalOpen}
          message="Do you want to delete this agency?"
          action={deleteAction}
        />
      )}
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="agency-list-container">
            {modalOpen && (
              <CreateAgencyModal
                style={{ position: "absolute" }}
                setModalOpen={setModalOpen}
              />
            )}

            <div className="agency-list">
              <div className="agency-label">Agencies</div>
              <div
                className="create-agency-btn"
                onClick={() => setModalOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <PlusIcon />
                <div className="create-agency-text">Create New Agency</div>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="agency_list_table_container"
            >
              <Table aria-label="simple table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell align="left" className="details_table_cell">
                      <span>Name</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Email Address</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Roles & Permission</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice((pageNo - 1) * 10, pageNo * 10).map((row) => (
                    <TableRow key={row?.calories} className="table-row">
                      <TableCell align="left" className="details_table_cell">
                        <span className="field-text">
                          {row?.tableFieldImgRow}
                        </span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span className="field-text">{row?.email}</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span className="field-text">{row?.permissions}</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>{row?.actionIcons}</span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationComponent
              rows={rows}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyListContainer;
