import React, { useEffect } from "react";
import { NotificationCard } from "../../components";
import { Link } from "react-router-dom";
import { TrueCheck } from "../../assets/images";
import "./notificationPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotification } from "../../redux/notificationsSlice";

const NotificationPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNotification());
  }, []);

  const notificationsSubSlice = useSelector(
    (state) => state?.notificationsSlice
  );
  return (
    <div className="notification_page">
      <div className="notification_page_header">
        <div>Notifications</div>
        <Link to="/notifications" style={{ textDecoration: "none" }}>
          <span>View All</span>
        </Link>
      </div>

      {notificationsSubSlice.status == "loading" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="loader margin"
        ></div>
      )}
      {Array.isArray(notificationsSubSlice.res) &&
        notificationsSubSlice.res.length > 1 &&
        notificationsSubSlice.res.map((notification) => (
          <NotificationCard notification={notification} />
        ))}

      {notificationsSubSlice.status == "success" &&
        Array.isArray(notificationsSubSlice.res) &&
        notificationsSubSlice.res.length == 0 && (
          <>
            <div className="notification">
              <div className="notification_container">
                <TrueCheck />
              </div>
              <div className="flex-column">
                <p>No new Notification</p>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default NotificationPage;
