import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DatePickerWrapper = ({ value, handleChange, minDate, maxDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        inputFormat="MM/DD/YYYY"
        value={value || null}
        onChange={handleChange}
        InputAdornmentProps={{ position: "start" }}
        minDate={minDate}
        maxDate={maxDate}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              svg: { color: "#4C4DDC" },
              input: { color: "#7183A5" },
              label: { color: "#7183A5" },
              borderColor: { color: "#A7B6D1" },
              "& .MuiOutlinedInput-root": {
                borderColor: "green",
                "& fieldset": {
                  borderColor: "#A7B6D1",
                },
                "&:hover fieldset": {
                  borderColor: "#4C4DDC",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#4C4DDC",
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerWrapper;
