import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// fetchEmployeeProfile
export const fetchEmployeeProfile = createAsyncThunk(
  "Employee/FetchProfile",
  async ({ uuid }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/employees/${uuid}`
    );
    return response.data;
  }
);

// fetchEmployeeReviews
export const fetchEmployeeReviews = createAsyncThunk(
  "Employee/FetchReviews",
  async ({ uuid }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/employees/${uuid}/reviews`
    );
    return response.data;
  }
);

// fetchTopRatedEmployee
export const fetchTopRatedEmployee = createAsyncThunk(
  "fetchTopRatedEmployee",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/employees/`);
    return res.data;
  }
);

// fetchAllEmployees
export const fetchAllEmployees = createAsyncThunk(
  "fetchAllEmployees",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/employees/`);
    return res.data;
  }
);
// DeleteEmployee
export const deleteEmployee = createAsyncThunk(
  "deleteEmployee",
  async (employeeUUID, { dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/employees/${employeeUUID}/delete`
      );
      dispatch(fetchAllEmployees());
      return employeeUUID;
    } catch (err) {
      dispatch(fetchAllEmployees());
    }
  }
);

// fetchEmployeeApplications
export const fetchEmployeeApplications = createAsyncThunk(
  "fetchEmployeeApplications",
  async (token, thunk) => {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token.access}`;

    // if (thunk.getState().employeeSlice.employeeApplications?.res)
    //   return thunk.getState().employeeSlice.employeeApplications.res;

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employees/applications`
    );
    return res.data;
  }
);

// approveEmployeeApplication
export const approveEmployeeApplication = createAsyncThunk(
  "approveEmployeeApplication",
  async ({ uuid, dispatch }, thunk) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employees/applications/${uuid}/approve`
    );
    thunk.dispatch(removeOneApplication(uuid));
    return res.data;
  }
);

// rejectEmployeeApplication
export const rejectEmployeeApplication = createAsyncThunk(
  "rejectEmployeeApplication",
  async ({ uuid, dispatch }) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/employees/applications/${uuid}/decline`
    );
    dispatch(removeOneApplication(uuid));

    return res.data;
  }
);

// fetchEmployeeTimeSheet
export const fetchEmployeeTimeSheet = createAsyncThunk(
  "fetchEmployeeTimeSheet",
  async (uuid) => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/timesheets?employee_uuid=${uuid}`
    );
    return res.data;
  }
);

const initialState = {
  Profile: {
    status: "initial state",
    res: {},
    error: null,
  },
  Reviews: {
    status: "initial state",
    res: {},
    error: null,
  },
  topRatedEmployees: { status: "initial state", res: {}, error: null },
  approveApplication: { status: "initial state", res: {}, error: null },
  rejectApplication: { status: "initial state", res: {}, error: null },
  employeeApplications: { status: "initial state", res: null, error: null },
  timeSheet: { status: "initial state", res: null, error: null },
  allEmployees: { status: "initial state", res: null, error: null },
};

const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState,

  reducers: {
    removeOneApplication: (state, payload) => {
      state.employeeApplications.res = state.employeeApplications.res.filter(
        (item) => item.uuid != payload.payload
      );
    },
  },

  extraReducers: {
    [fetchEmployeeProfile.pending]: (state) => {
      state.Profile.status = "loading";
      state.Profile.res = {};
    },
    [fetchEmployeeProfile.fulfilled]: (state, action) => {
      state.Profile.status = "success";
      state.Profile.res = action.payload;
      state.Profile.error = null;
    },
    [fetchEmployeeProfile.rejected]: (state, action) => {
      state.Profile.status = "failed";
      state.Profile.res = {};
      state.Profile.error = action.error;
    },

    // fetchEmployeeReviews

    [fetchEmployeeReviews.pending]: (state) => {
      state.Reviews.status = "loading";
    },
    [fetchEmployeeReviews.fulfilled]: (state, action) => {
      state.Reviews.status = "success";
      state.Reviews.res = action.payload;
      state.Reviews.error = null;
    },
    [fetchEmployeeReviews.rejected]: (state, action) => {
      state.Reviews.status = "failed";
      state.Reviews.res = {};
      state.Reviews.error = action.error;
    },

    // fetchTopRatedEmployee

    [fetchTopRatedEmployee.pending]: (state) => {
      state.topRatedEmployees.status = "loading";
    },
    [fetchTopRatedEmployee.fulfilled]: (state, action) => {
      state.topRatedEmployees.status = "success";
      state.topRatedEmployees.res = action.payload;
      state.topRatedEmployees.error = null;
    },
    [fetchTopRatedEmployee.rejected]: (state, action) => {
      state.topRatedEmployees.status = "failed";
      state.topRatedEmployees.res = {};
      state.topRatedEmployees.error = action.error;
    },

    // approve employee application

    [approveEmployeeApplication.pending]: (state) => {
      state.approveApplication.status = "loading";
    },
    [approveEmployeeApplication.fulfilled]: (state, action) => {
      state.approveApplication.status = "success";
      state.approveApplication.res = action.payload;
      state.approveApplication.error = null;
    },
    [approveEmployeeApplication.rejected]: (state, action) => {
      state.approveApplication.status = "failed";
      state.approveApplication.res = {};
      state.approveApplication.error = action.error;
    },

    // reject employee application

    [rejectEmployeeApplication.pending]: (state) => {
      state.rejectApplication.status = "loading";
    },
    [rejectEmployeeApplication.fulfilled]: (state, action) => {
      state.rejectApplication.status = "success";
      state.rejectApplication.res = action.payload;
      state.rejectApplication.error = null;
    },
    [rejectEmployeeApplication.rejected]: (state, action) => {
      state.rejectApplication.status = "failed";
      state.rejectApplication.res = {};
      state.rejectApplication.error = action.error;
    },

    // reject employee application

    [fetchEmployeeApplications.pending]: (state) => {
      state.employeeApplications.status = "loading";
    },
    [fetchEmployeeApplications.fulfilled]: (state, action) => {
      state.employeeApplications.status = "success";
      state.employeeApplications.res = action.payload;
      state.employeeApplications.error = null;
    },
    [fetchEmployeeApplications.rejected]: (state, action) => {
      state.employeeApplications.status = "failed";
      state.employeeApplications.res = {};
      state.employeeApplications.error = action.error;
    },

    //fetchEmployeeTimeSheet

    [fetchEmployeeTimeSheet.pending]: (state) => {
      state.timeSheet.status = "loading";
    },
    [fetchEmployeeTimeSheet.fulfilled]: (state, action) => {
      state.timeSheet.status = "success";
      state.timeSheet.res = action.payload;
      state.timeSheet.error = null;
    },
    [fetchEmployeeTimeSheet.rejected]: (state, action) => {
      state.timeSheet.status = "failed";
      state.timeSheet.res = {};
      state.timeSheet.error = action.error;
    },

    //fetchAllEmployees

    [fetchAllEmployees.pending]: (state) => {
      state.allEmployees.status = "loading";
    },
    [fetchAllEmployees.fulfilled]: (state, action) => {
      state.allEmployees.status = "success";
      state.allEmployees.res = action.payload;
      state.allEmployees.error = null;
    },
    [fetchAllEmployees.rejected]: (state, action) => {
      state.allEmployees.status = "failed";
      state.allEmployees.res = {};
      state.allEmployees.error = action.error;
    },

    //fetchAllEmployees

    [deleteEmployee.pending]: (state) => {
      state.allEmployees.status = "loading";
    },
    [deleteEmployee.fulfilled]: (state, action) => {
      state.allEmployees.status = "success";
      state.allEmployees.res = state.allEmployees.res.filter(
        (employee) => employee.uuid !== action.payload
      );
      state.allEmployees.error = null;
    },
    // [deleteEmployee.rejected]: (state, action) => {
    //   state.allEmployees.status = "failed";
    //   state.allEmployees.res = {};
    //   state.allEmployees.error = action.error;
    // },
  },
});

export const { removeOneApplication } = employeeSlice.actions;

export default employeeSlice.reducer;
