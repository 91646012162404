export const getShiftTime = (start_time, end_time) => {
  let startTime = new Date(start_time);
  let endTime = new Date(end_time);
  startTime = startTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  endTime = endTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return `${startTime} to ${endTime}`;
};

export const getShiftDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const getDateToFromString = (start_time, end_time) => {
  const startDateStr = getShiftDate(start_time);
  const endDateStr = getShiftDate(end_time);
  if (startDateStr == endDateStr) {
    return startDateStr;
  } else return `${startDateStr} to ${endDateStr}`;
};
