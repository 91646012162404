import React, { useState } from "react";
import {
  ProfileCard,
  SideBarMenu,
  AppBar,
  ProfileInfoCard,
} from "../../components";
import "./homePage.scss";

import { getImage } from "../../utils/getAwsFile";
import { fetchTopRatedEmployee } from "../../redux/employeeSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useCookies from "react-cookie/cjs/useCookies";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [topRatedImages, setTopRatedImages] = useState();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const topRatedEmployeeSubSlice = useSelector(
    (state) => state.employeeSlice.topRatedEmployees
  );

  useEffect(() => {
    if (cookies?.token?.access) {
      dispatch(fetchTopRatedEmployee());
    }
  }, [cookies.token]);

  useEffect(() => {
    if (topRatedEmployeeSubSlice.status !== "success") return;

    const imagePromises = topRatedEmployeeSubSlice.res
      .filter((emp) => emp.profile.is_active === true)
      .map((obj) =>
        obj.image_aws_object_key
          ? getImage(obj.image_aws_object_key)
          : Promise.resolve(null)
      );
    Promise.all(imagePromises).then((images) => {
      setTopRatedImages(images);
    });
  }, [topRatedEmployeeSubSlice.res]);

  // const userInfoSubSlice = useSelector((state) => state?.userInfoSlice);

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <>
            <section>
              <div className="homepage_content-header">
                Top Rated
                <Link to="/top-rated">
                  <span>View All</span>
                </Link>
              </div>
              {topRatedEmployeeSubSlice.status === "success" ? (
                <div className="homepage_content-box">
                  {topRatedEmployeeSubSlice?.res
                    ?.filter((emp) => emp.profile.is_active === true)
                    ?.slice(0, 6)
                    ?.map((topRatedEmployee, index) => (
                      <ProfileCard
                        img={topRatedEmployee.image_aws_object_key}
                        key={topRatedEmployee.id}
                        employee={topRatedEmployee}
                      />
                    ))}
                </div>
              ) : (
                <div className="loader margin"></div>
              )}
            </section>
            <section>
              <div className="homepage_content-header">
                Available Now
                <Link to="/available-now">
                  <span>View All</span>
                </Link>
              </div>
              {topRatedEmployeeSubSlice.status === "success" ? (
                <div className="homepage_content-box">
                  {topRatedEmployeeSubSlice?.res
                    ?.filter((emp) => emp.profile.is_active === true)
                    ?.slice(0, 6)
                    ?.map((topRatedEmployee, index) => (
                      <ProfileCard
                        img={topRatedEmployee.image_aws_object_key}
                        key={topRatedEmployee.id}
                        employee={topRatedEmployee}
                      />
                    ))}
                </div>
              ) : (
                <div className="loader margin"></div>
              )}
            </section>
          </>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
