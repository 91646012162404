import React from "react";
import "./notificationCard.scss";
import { NotificationPageIcon } from "../../assets/images";

function formatTime(dateString) {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime;
}

const notificationCard = ({ notification }) => {
  return (
    <>
      <div className="notification flex">
        <div className="notification_container">
          <NotificationPageIcon />
        </div>
        <div className="flex-column flex-1 ">
          <p>
            {notification.message}
            <span>{formatTime(notification.created_at)}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default notificationCard;
