import React, { createRef, useEffect, useState } from "react";
import "./TimeSheetMarkup.scss";
import { ReactComponent as Logo } from "./logo.svg";
import { getImage } from "../../utils/getAwsFile";
import html2canvas from "html2canvas";

import { useScreenshot, createFileName } from "use-react-screenshot";
import jsPDF from "jspdf";

export const TimeSheetMarkup = ({ entry }) => {
  const [sign, setSign] = useState(null);

  const getDate = (date) => {
    const time = new Date(date);
    const d = time.toLocaleString("en-us");

    return d;
  };

  useEffect(() => {
    getImage(entry["sign_aws_object_key"]).then((data) => setSign(data));
  }, [entry]);

  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image, { name = "timesheet", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  // const downloadScreenshot = () => takeScreenshot(ref.current).then(download);
  const downloadScreenshot = () => {
    html2canvas(ref.current).then((canvas) => {

      let dataURL = canvas.toDataURL("image/jpeg");

      const pdf = new jsPDF("p", "px", [canvas.width, canvas.height]);

      pdf.addImage(dataURL, "JPEG", 1, 1, canvas.width, canvas.height);
      pdf.save(`${entry["Employee Name"]}'s timesheet.pdf`);
    });

    takeScreenshot(ref.current).then((img) => {
    });
  };

  return (
    <>
      <div className="timesheet-markup" ref={ref}>
        <header>
          <h2>TIMESHEET</h2>
          <Logo />
        </header>
        <main>
          <section>
            <div className="name column">
              Employee:{" "}
              <span>
                {entry["Employee First Name"] +
                  " " +
                  entry["Employee Last Name"]}
              </span>
            </div>
          </section>
          <span className="name">
            Facility: <span>{entry["Facility Name"]}</span>
          </span>
          <span className="name">
            Isolation: <span>{entry["isolation"]? "Yes":"No"}</span>
          </span>
          <section>
            <div className="name clock">
              Clock in Time: <span>{getDate(entry["clock_in_time"])}</span>
            </div>
            <div className="name clock">
              Clock out Time: <span>{getDate(entry["clock_out_time"])}</span>
            </div>
          </section>
          <div className="name">
            Lunch: <span>{entry["lunch"] ? "Yes" : "No"}</span>
          </div>
          {!entry["lunch"] && (
            <div className="name">
              No lunch reason : <span>{entry["lunch_reason"]}</span>
            </div>
          )}
          <section>
            <div className="name">
              Signer name: <span>{entry["signer_name"]}</span>
            </div>
            <div className="name">
              Date: <span>{getDate(entry["modified_at"])}</span>
            </div>
          </section>
          <div className="name column">
            Position: <span>{entry["signer_position"]}</span>
          </div>
          <div className="signature">
            <h3>Signature:</h3>
            {sign && (
              <img alt="signature" src={`data:image/jpeg;base64,${sign}`} />
            )}
          </div>
        </main>
      </div>
      <button className="timesheet__btn" onClick={downloadScreenshot}>
        Download
      </button>
    </>
  );
};
