import React from "react";
import { useState } from "react";
import FacilityApplicationContainer from "../facilityApplicationContainer";
import EmployeeApplicationContainer from "../../employeeApplicationContainer/employeeApplicationContainer";
import { useDispatch, useSelector } from "react-redux";
import ActiveEmployeeContainer from "../../../components/ActiveEmployeeContainer/ActiveEmployeeContainer";
import { Dispatch } from "react";

import { setApplication } from "../../../redux/tempStorageSlice";

export const ApplicationsContainer = () => {
  const dispatch = useDispatch();

  const select = useSelector((state) => state.tempStorageSlice.application);
  const setSelect = (value) => {
    dispatch(setApplication(value));
  };

  if (select == "Employee")
    return <ActiveEmployeeContainer setSelect={setSelect} select={select} />;
  return <FacilityApplicationContainer setSelect={setSelect} select={select} />;
};
