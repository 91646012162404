import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { Stack } from "@mui/material";

function PaginationComponent({ rows, pageNo, setPageNo }) {
  const [tempPageNo, setTempPageNo] = useState(1);

  useEffect(() => {
    setTempPageNo(pageNo);
  }, [pageNo]);

  return (
    <div className="pagination-container">
      <Stack spacing={2}>
        <Pagination
          page={parseInt(pageNo)}
          count={rows.length > 0 ? Math.ceil(rows.length / 10) : 1}
          variant="outlined"
          shape="rounded"
          className="agency-pagination"
          onChange={(_, page) => setPageNo(page)}
        />
      </Stack>
      <div className="current-page-container">
        <div className="current-page">Page</div>
        <div className="page-count" style={{ paddingRight: "14px" }}>
          <span style={{ maxWidth: "40px", display: "inline-block" }}>
            <input
              type="number"
              value={tempPageNo}
              onChange={(e) => setTempPageNo(e.target.value)}
              min="1"
              max={rows.length > 0 ? Math.ceil(rows.length / 10) : 1}
              style={{
                border: "none",
                outline: "none",
                width: "100%",
                font: "inherit",
                textAlign: "center",
              }}
            />
          </span>
        </div>
        <div className="total-page">
          of {rows.length > 0 ? Math.ceil(rows.length / 10) : 1}
        </div>
        <div className="next-page" onClick={() => setPageNo(tempPageNo)}>
          <span>Go</span>
        </div>
      </div>
    </div>
  );
}

export default PaginationComponent;
