import "./profileSettingsBtn.scss";

const profileSettingsBtn = ({ settingOptions, content, viewMore }) => {
  return (
    <div className="profile_settings cursor-pointer">
      <div className="profile_settings_container">
        {settingOptions}
        <span>{content}</span>
      </div>
      {viewMore && viewMore}
    </div>
  );
};
export default profileSettingsBtn;
