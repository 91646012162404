import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import "./addNewFacilityModal.scss";

import { useDispatch } from "react-redux";
import { createNewFacility } from "../../redux/facilitySlice";

import { ModalCloseIcon } from "../../assets/images";

import "./addNewFacilityModal.scss";

export const AddNewFacilityModal = ({ setModalOpen }) => {
  const [firstName, setFirstName] = useState();
  const [email, setemail] = useState();
  const [userName, setuserName] = useState();
  const [street, setstreet] = useState();
  const [city, setcity] = useState();
  const [state, setstate] = useState();
  const [zipCode, setzipCode] = useState();
  const [error, setError] = useState();
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [additionalDetails, setAdditionalDetails] = useState();
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [base64Imgs, setBase64Imgs] = useState([]);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (
      !(
        email &&
        firstName &&
        userName &&
        street &&
        city &&
        state &&
        zipCode &&
        additionalDetails
      )
    ) {
      setError("Please fill out all fields");
      return;
    } else setError();

    const body = {
      files: base64Imgs,
      profile: {
        user: {
          username: userName,
          email: email,
          first_name: firstName,
          last_name: String(Math.floor(Math.random() * 100)),
          role: "Facility",
          password: "O_o",
        },
      },
      name: `${firstName}`,
      description: additionalDetails,
      address: {
        street1: street,
        city: city,
        state: state,
        zipcode: zipCode,
      },
    };

    dispatch(createNewFacility(body)).then((response) => {
      response.meta.requestStatus == "fulfilled" &&
        setSuccessfullyCreated(true);
      response.error && setError(Object.values(response.payload)[0]);
    });
  };
  useEffect(() => {
    if (images.length < 1) return;

    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(newImageURLs);
  }, [images]);

  const onImageChange = function (e) {
    setImages([...e.target.files]);

    [...e.target.files].map((img, index) => {
      index == "0" && setBase64Imgs([]);
      getBase64(img, (result) => {
        setBase64Imgs((prevImages) => [
          ...prevImages,
          {
            type: "IMAGE",
            file: result.replace(/^data:image\/\w+;base64,/, ""),
          },
        ]);
      });
    });
  };

  if (successfullyCreated) {
    return (
      <>
        <div className="add-facility-modal-wrapper">
          <div className="add-facility-modal" style={{ position: "relative" }}>
            <ModalCloseIcon
              style={{
                position: "absolute",
                top: "16px",
                right: "24px",
                cursor: "pointer",
              }}
              onClick={() => setModalOpen(false)}
            />
            <h2
              style={{
                color: "green",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                fontWeight: "500",
                fontSize: "20px",
                minHeight: "400px",
              }}
            >
              Facility Created successfully
            </h2>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="add-facility-modal-wrapper">
        <div className="add-facility-modal">
          <header>
            <h2>Add New Facility</h2>
            <ModalCloseIcon
              onClick={() => setModalOpen(false)}
              style={{ cursor: "pointer" }}
            />
          </header>
          <div className="description">Please add details to Add Facility</div>
          <div className="two-inputs">
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="Name"
                className="full-name-input-field"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label>
          </div>
          <div className="two-inputs">
            <label className="email-input">
              {/* prettier-ignore */}
              <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00333343 1.88355L7.99995 5.88186L15.9967 1.8835C15.9363 0.833152 15.0655 0 14 0H2C0.934518 0 0.0636347 0.833179 0.00333343 1.88355Z" fill="#C5D4EB"/>
<path d="M16 4.1179L7.99995 8.11793L0 4.11796V10C0 11.1046 0.895431 12 2 12H14C15.1046 12 16 11.1046 16 10V4.1179Z" fill="#C5D4EB"/>
</svg>
              <input
                type="email"
                placeholder="Email"
                className="email-input-field"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </label>
          </div>

          <div className="two-inputs">
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="Username"
                className="full-name-input-field"
                value={userName}
                onChange={(e) => setuserName(e.target.value)}
              />
            </label>
          </div>

          <div className="two-inputs">
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="Street"
                className="full-name-input-field"
                value={street}
                onChange={(e) => setstreet(e.target.value)}
              />
            </label>
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="City"
                className="full-name-input-field"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              />
            </label>
          </div>
          <div className="two-inputs">
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="State"
                className="full-name-input-field"
                value={state}
                onChange={(e) => setstate(e.target.value)}
              />
            </label>
            <label className="full-name-input">
              {/* prettier-ignore */}
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 6C8.65685 6 10 4.65685 10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3C4 4.65685 5.34315 6 7 6Z" fill="#C5D4EB"/>
<path d="M0 15C0 11.134 3.13401 8 7 8C10.866 8 14 11.134 14 15H0Z" fill="#C5D4EB"/>
</svg>
              <input
                type="text"
                placeholder="Zipcode"
                className="full-name-input-field"
                value={zipCode}
                onChange={(e) => setzipCode(e.target.value)}
              />
            </label>
          </div>

          <label className="additional-details">
            <textarea
              type="text"
              placeholder="Add additional details"
              className="additional-details"
              value={additionalDetails}
              onChange={(e) => setAdditionalDetails(e.target.value)}
            />
          </label>
          <div className="upload-image-text">Upload images</div>

          <label className="upload-image">
            <>
              <div className="svg-wrapper">
                {/* prettier-ignore */}
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.600098 18.4004C0.600098 17.7376 1.13736 17.2004 1.8001 17.2004H16.2001C16.8628 17.2004 17.4001 17.7376 17.4001 18.4004C17.4001 19.0631 16.8628 19.6004 16.2001 19.6004H1.8001C1.13736 19.6004 0.600098 19.0631 0.600098 18.4004ZM4.55157 6.04892C4.08294 5.58029 4.08294 4.82049 4.55157 4.35186L8.15157 0.751863C8.37661 0.526819 8.68184 0.400391 9.0001 0.400391C9.31836 0.400391 9.62358 0.526819 9.84862 0.751863L13.4486 4.35186C13.9173 4.82049 13.9173 5.58029 13.4486 6.04892C12.98 6.51755 12.2202 6.51755 11.7516 6.04892L10.2001 4.49745L10.2001 13.6004C10.2001 14.2631 9.66284 14.8004 9.0001 14.8004C8.33735 14.8004 7.8001 14.2631 7.8001 13.6004L7.8001 4.49745L6.24863 6.04892C5.78 6.51755 5.0202 6.51755 4.55157 6.04892Z" fill="#A7B6D1"/>
</svg>
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={onImageChange}
                style={{ display: "none" }}
                // onClick={() => setBase64Imgs([])}
              ></input>
              <div className="pseudo-btn">
                <span>Drag and drop or&nbsp;</span>

                <span style={{ color: "#4C8BF5" }}>browse file</span>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    marginBottom: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  {imageURLs.length > 0 && `${imageURLs.length} selected`}
                </div>
              </div>
            </>

            <div className="selected-images">
              {imageURLs.map((imageSrc, index) => (
                <div className="selected-image-div">
                  <span>{index + 1} : </span>
                  <img
                    src={imageSrc}
                    className="selected-img"
                    style={{ maxWidth: "80%" }}
                  />
                </div>
              ))}
            </div>
          </label>
          <div
            className="error-div mt-3"
            style={{ color: "red", textAlign: "center", fontSize: "15px" }}
          >
            {error}
          </div>
          <button className="add-facility-btn" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </>
  );

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.error("Error 💣: ", error);
    };
  }
};
