import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { NodLogo } from "../../assets/images";
import { changePassword } from "../../redux/userLoginSlice.js";
import { ReactComponent as LOCK } from "../../assets/images/lock-closed.svg";
import Hidden from "../../assets/images/hidden.svg";
import { useNavigate } from "react-router-dom";
import SplashCarousel from "../../components/SplashCarousel/SplashCarousel";

import "./changeMyPassword.scss";

const ChangeMyPassword = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassowrd, setConfirmPassword] = useState();
  const [hidden, setHidden] = useState([false, false, false]);
  const navigate = useNavigate();

  const HandleLBtn = () => {
    setError("");
    if (!(currentPassword && newPassword && confirmPassowrd)) return setError("please fill all the fields");

    if (newPassword !== confirmPassowrd) return setError("new passwords don't match");

    if (newPassword.length < 8) return setError("password must contain at least 8 characters");

    if (!(/[A-Z]/.test(newPassword) && /\d/.test(newPassword)))
      return setError("password must contain at least one uppercase letter and one number");

    const body = {
      old_password: currentPassword,
      new_password: newPassword,
    };
    dispatch(changePassword(body)).then((data) => {
      data.meta.requestStatus == "fulfilled" && navigate("/profile");
      data.meta.requestStatus == "rejected" && setError(data.payload.message);
    });
  };

  return (
    <div className="change-password-container">
      <div className="d-flex login align-center" style={{ background: "white" }}>
        <div className="d-flex login_description_page justify-center align-center">
          <div className="flex-column align-center">
            <div className="logo_icon">
              <NodLogo />
            </div>
            <SplashCarousel />
          </div>
        </div>

        <div className="d-flex login_container align-center justify-center">
          <div className="flex-column align_center justify_center">
            <div className="login_header">Change your password</div>

            <form className="form">
              <label className="box">
                <LOCK />
                <input
                  autocomplete="current-password"
                  type={hidden[0] ? "text" : "password"}
                  className="form_email"
                  placeholder="Current password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <img src={Hidden} onClick={() => setHidden([!hidden[0], hidden[1], hidden[2]])} alt="" />
              </label>

              <label className="box">
                <LOCK />
                <input
                  className="form_email"
                  autocomplete="new-password"
                  type={hidden[1] ? "text" : "password"}
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img src={Hidden} onClick={() => setHidden([hidden[0], !hidden[1], hidden[2]])} alt="" />
              </label>

              <label className="box">
                <LOCK />
                <input
                  className="form_email"
                  autocomplete="new-password"
                  type={hidden[2] ? "text" : "password"}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img src={Hidden} onClick={() => setHidden([hidden[0], hidden[1], !hidden[2]])} alt="" />
              </label>
              {error && <p className="login_page_error_text">{error}</p>}
            </form>
            <button className="login_container_button" onClick={HandleLBtn}>
              <span className="login_container_button_text">Change my password</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeMyPassword;
