import { AgencyCountCard } from "../../components";
import { AppBar, SideBarMenu } from "../../components";
import {
  SeenIcon,
  BlockIcon,
  TableFieldImg,
  NotificationHomePageIcon,
  TrueCheck,
} from "../../assets/images";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { fetchAgenciesList } from "../../redux/agencySlice";
import { useSelector } from "react-redux";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./superAdminDashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import { fetchNotification } from "../../redux/notificationsSlice";
import { ConfirmDeleteModal } from "../../components/confirmDeleteModal/confirmDeleteModal";

import { FiEdit } from "react-icons/fi";

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const [deleteAgencyModalOpen, setDeleteAgencyModalOpen] = useState(false);
  const [deleteAction, setDeleteAction] = useState();

  const navigate = useNavigate();

  function createData(tableFieldImgRow, email, permissions, actionIcons) {
    return { tableFieldImgRow, email, permissions, actionIcons };
  }

  useEffect(() => {
    dispatch(fetchAgenciesList());
    dispatch(fetchNotification());
  }, []);

  const notificationsSubSlice = useSelector(
    (state) => state.notificationsSlice
  );

  const agenciesListSubSlice = useSelector(
    (state) => state.agencySlice.agenciesList
  );
  let agencies = Array.isArray(agenciesListSubSlice.res) && [
    ...agenciesListSubSlice.res,
  ];

  let sortedAgencies =
    Array.isArray(agencies) &&
    agencies.sort((a, b) => {
      const dateA = new Date(a?.profile?.user?.date_joined);
      const dateB = new Date(b?.profile?.user?.date_joined);
      return dateB - dateA;
    });

  // IF "agenciesListSubSlice.res" IS ARRAY, MAP IT'S ELEMENTS TO ROWS OTHERWISE JSUT STORE AN EMPTY ARRAY IN ROWS
  const rows = Array.isArray(agenciesListSubSlice.res)
    ? sortedAgencies.slice(0, 7).map((agency) => {
        // SETTING PERMISSION OF AGENCY
        let permissions;

        switch (true) {
          case agency.permissions.includes("AE") &&
            agency.permissions.includes("DE"):
            permissions = "Approve & Delete Employees";
            break;
          case agency.permissions.includes("AE"):
            permissions = "Approve Employees";
            break;
          case agency.permissions.includes("DE"):
            permissions = "Delete Employees";
            break;
          default:
            permissions = "-";
            break;
        }

        return createData(
          <div className="table-field-img-row">
            <TableFieldImg />
            <div>{agency.name}</div>
          </div>,
          agency.profile.user.email,
          permissions,
          <div className="action-icons">
            <SeenIcon
              className="cursor-pointer"
              onClick={() =>
                navigate(`/agency-details/${agency.profile.user.uuid}`)
              }
            />
            <FiEdit
              className="cursor-pointer"
              style={{
                width: "24px",
                height: "24px",
                background: "rgba(0, 128, 0, 0.1)",
                padding: "4.6px 4px",
                borderRadius: "4px",
              }}
              onClick={() => {
                navigate(`/agency-settings/${agency.profile.user.uuid}`);
              }}
            />
            <BlockIcon
              className="cursor-pointer"
              onClick={() => {
                setDeleteAgencyModalOpen(true);
                setDeleteAction({
                  name: "deleteAgency",
                  body: agency.profile.user.uuid,
                });
              }}
            />
          </div>
        );
      })
    : [];

  return (
    <div className="homepage">
      {deleteAgencyModalOpen && (
        <ConfirmDeleteModal
          setConfirmDeleteModalOpen={setDeleteAgencyModalOpen}
          message="Do you want to delete this agency?"
          action={deleteAction}
        />
      )}
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="super-admin-container">
            <div className="super-admin">
              <div className="dashboard-header">Dashboard</div>
              <AgencyCountCard
                totalAgenciesCount={
                  (Array.isArray(agenciesListSubSlice.res) &&
                    agenciesListSubSlice.res.length) ||
                  "..."
                }
              />
              <div className="recent-agencies-header">Recent Agencies</div>
              <TableContainer
                component={Paper}
                className="agency_list_table_container"
              >
                <Table aria-label="simple table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="left" className="details_table_cell">
                        <span>Name</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Roles & Permission</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Action</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.calories} className="table-row">
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">
                            {row.tableFieldImgRow}
                          </span>
                        </TableCell>
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row.permissions}</span>
                        </TableCell>
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row.actionIcons}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="side-notifications">
              <div className="alerts-header">
                <div className="alerts">Alerts</div>
                <Link
                  to="/notifications"
                  style={{ cursor: "pointer", textDecoration: "none" }}
                >
                  {/* <Link to="/notifications" className="text-deco-none"> */}
                  <div className="view-notifications">View Notifications</div>
                  {/* </Link> */}
                </Link>
              </div>
              {notificationsSubSlice.status === "loading" &&
              !Array.isArray(notificationsSubSlice?.res) ? (
                <div
                  style={{ display: "flex", margin: "auto", marginTop: "64px" }}
                  className="loader margin"
                ></div>
              ) : notificationsSubSlice?.status === "success" &&
                notificationsSubSlice?.res?.length > 0 ? (
                notificationsSubSlice?.res.map((notification) => (
                  <div className="notification-card">
                    <NotificationHomePageIcon />
                    <div className="notification-content-container">
                      <div className="notication-content">
                        {notification.message}
                      </div>
                      <div className="notification-time">
                        {formatTime(notification.created_at)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="notification-card">
                  <TrueCheck />
                  <div className="notification-content-container">
                    <div className="notication-content">No Notifications </div>
                    <div className="notification-time"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function formatTime(dateString) {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  }
};

export default SuperAdminDashboard;
