import React, { useState } from "react";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

import { CloseModal } from "../../assets/images";
import { TextField } from "@mui/material";

import "./currentContainer.scss"

 const CurrentContainer = ({setReviewsModalOpen}) => {
    const [value, setValue] = useState();

    return (
      <div className="give-employee-review-modal-wrapper">
        <div className="give-employee-review-modal"> 
          <div className="header"> 
            <h2>Feedback</h2>
            <div className="close-btn" onClick={()=>setReviewsModalOpen(false)}>
            <CloseModal />
            </div>
          </div>
          <div className="review-rows">


          <div className="review-row">
<div className="text">Rate the employee on scale of 1-5</div>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          </div>
          <div className="review-row">

          <div className="text">Any FeedBack</div>
                <TextField id="outlined-basic" label="Feedback" variant="outlined" />
</div>
          </div>
          <button className="btn">Submit</button>
        </div>
      </div>
    );
  }



  export default CurrentContainer;