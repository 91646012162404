import { AppBar, SideBarMenu } from "../../components";

import { useEffect } from "react";

import {
  PlusIcon,
  SeenIcon,
  BlockIcon,
  TableFieldImg,
  DropdownIcon,
  TrueCheck,
} from "../../assets/images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";

import {
  rejectEmployeeApplication,
  fetchTopRatedEmployee,
  fetchAllEmployees,
  deleteEmployee,
} from "../../redux/employeeSlice";
import { useDispatch, useSelector } from "react-redux";

import useCookies from "react-cookie/cjs/useCookies";
import { ConfirmDeleteModal } from "../confirmDeleteModal/confirmDeleteModal";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";

export const ActiveEmployeeContainer = ({ select, setSelect }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [action, setAction] = useState();

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  useEffect(() => {
    dispatch(fetchAllEmployees());
  }, []);

  const activeEmployees = useSelector(
    (state) => state.employeeSlice.allEmployees.res
  );
  const activeEmployeesSubSlice = useSelector(
    (state) => state.employeeSlice.allEmployees
  );

  useEffect(() => {
    const tempRows = Array.isArray(activeEmployees)
      ? activeEmployees.map((employee) => {
          return createData(
            <div className="table-field-img-row">
              {`${employee.profile.first_name} ${employee.profile.last_name}`}
            </div>,
            `${employee.profile.email}`,
            <div className="action-icons">
              <SeenIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/employee-details/${employee.profile.uuid}`)
                }
              />
              {/* <TrueCheck
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    approveEmployeeApplication({
                      uuid: application.profile.uuid,
                      dispatch,
                    })
                  )
                }
              />*/}
              <BlockIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAction({
                    name: "deleteEmployee",
                    body: { employeeUUID: employee.profile.uuid },
                  });
                  setConfirmDeleteModalOpen(true);
                }}
              />
            </div>
          );
        })
      : [];
    setRows(tempRows);
  }, [activeEmployees]);

  return (
    <div className="homepage">
      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          setConfirmDeleteModalOpen={setConfirmDeleteModalOpen}
          action={action}
          message="Do you want to delete this employee?"
        />
      )}
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="employee-list-container">
            <div className="employee-list">
              <div className="employee-label-container">
                <div className="employee-label">Active Employees</div>
                {/* <div className="employee-subtitle">
                  Employee Account Request
                </div> */}
              </div>
              <div className="create-employee-btn-container">
                <Select
                  className="employee-dropdown"
                  value={select}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                  <MenuItem value={"Facility"}>Facility</MenuItem>
                </Select>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="employee_list_table_container"
            >
              <Table aria-label="simple table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell align="left" className="details_table_cell">
                      <span>Name</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Email Address</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeEmployeesSubSlice?.status == "loading" && (
                    <tr>
                      <td className="margin-center" colSpan="5">
                        <div className="loader margin"></div>
                      </td>
                    </tr>
                  )}
                  {activeEmployeesSubSlice?.status == "success" &&
                    activeEmployeesSubSlice?.res?.length > 0 &&
                    rows.slice((pageNo - 1) * 10, pageNo * 10).map((row) => (
                      <TableRow key={row?.name} className="table-row">
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row?.name}</span>
                        </TableCell>
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row?.calories}</span>
                        </TableCell>
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row?.fat}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  {activeEmployeesSubSlice?.status == "success" &&
                    activeEmployeesSubSlice?.res?.length == 0 && (
                      <tr>
                        <TableCell colSpan={3}>
                          <span>No Active Employee</span>
                        </TableCell>
                      </tr>
                    )}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationComponent
              rows={rows}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveEmployeeContainer;
