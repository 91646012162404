import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import thunk from "redux-thunk";

export const fetchNotification = createAsyncThunk(
  "fetchNotifications",
  async (_, thunk) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notifications/`
      );
      return response.data;
    } catch (err) {
    }
  }
);
const initialState = {
  status: "initial state",
  res: {},
  error: null,
};

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  extraReducers: {
    [fetchNotification.pending]: (state) => {
      state.status = "loading";
    },
    [fetchNotification.fulfilled]: (state, action) => {
      state.status = "success";
      state.res = action.payload;
      state.error = null;
    },
    [fetchNotification.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.res = {};
      state.error = payload;
    },
  },
});

export default notificationsSlice.reducer;
