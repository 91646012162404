import React from "react";
import { ReferenceDetails } from "../../../../components";
import "./ReferenceContainer.scss";

const ReferenceContainer = ({ data }) => {
  const [ref1, ref2] = data;
  return (
    <div className="reference-info">
      <div className="d-flex reference-content-gap">
        <ReferenceDetails
          label="Reference 1"
          name={ref1?.name || "-"}
          known={ref1?.years_known ?? "-"}
          email={ref1?.email || "-"}
          number={ref1?.phone_number || "-"}
          relation={ref1?.relationship || "-"}
        />
        <ReferenceDetails
          label="Reference 1"
          name={ref2?.name || "-"}
          known={ref2?.years_known ?? "-"}
          email={ref2?.email || "-"}
          number={ref2?.phone_number || "-"}
          relation={ref2?.relationship || "-"}
        />
      </div>
    </div>
  );
};

export default ReferenceContainer;
