import AWS from "aws-sdk";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

var s3 = new AWS.S3();

export const getImage = async (key) => {
  try {
    const params = {
      Bucket: "elasticbeanstalk-us-east-1-323377162080",
      Key: key,
    };

    const data = await s3.getObject(params).promise();

    return data.Body.toString("base64");
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`);
  }
};

export const getFileSignedUrl = (aws_object_key) => {
  const params = {
    Bucket: "elasticbeanstalk-us-east-1-323377162080",
    Key: aws_object_key,
    Expires: 60,
  };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};
