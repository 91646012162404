import React, { useEffect, useRef, useState } from "react";
import { BackIcon, Maya, DownloadDetailIcon } from "../../assets/images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./timeSheetPage.scss";
import { DatePickerWrapper, SideBarMenu, AppBar } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployeeTimeSheet } from "../../redux/employeeSlice";
import { Link } from "react-router-dom";
import { TimeSheetMarkup } from "../../components/TimeSheetMarkup/TimeSheetMarkup";

import {
  formatDate,
  formatTime,
  formatTimeDiff,
} from "../../utils/timesheetUtils";

import dayjs from "dayjs";
import { fetchEmployeeProfile } from "../../redux/employeeSlice";
import { getImage } from "../../utils/getAwsFile";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const TimeSheetPage = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [picture, setPicture] = useState(null);
  const [rows, setRows] = useState([]);

  const [modal, setModal] = useState(false);
  const [entry, setEntry] = useState(null);

  function createData(name, calories, fat, carbs, protein, isolation, uuid) {
    return { name, calories, fat, carbs, protein, isolation, uuid };
  }

  useEffect(() => {
    dispatch(fetchEmployeeTimeSheet(uuid));
    dispatch(fetchEmployeeProfile({ uuid }));
  }, []);

  const employeeTimeSheetSubslice = useSelector(
    (state) => state.employeeSlice.timeSheet
  );
  const employeeProfile = useSelector(
    (state) => state.employeeSlice?.Profile?.res?.profile?.user
  );
  const files = useSelector(
    (state) => state.employeeSlice?.Profile?.res?.files
  );

  useEffect(() => {
    if (files) {
      files.length > 0 &&
        getImage(files[0].aws_object_key).then((data) => {
          setPicture(data);
        });
    }
  }, [files]);

  useEffect(() => {
    // guard clause
    if (!Array.isArray(employeeTimeSheetSubslice.res)) return;
    // doing deep copy to mutate entriesArray based on start and end date
    let entriesArray = [...employeeTimeSheetSubslice.res];
    if (startDate) {
      const startDateObj = new Date(startDate);
      entriesArray = entriesArray.filter(
        (entry) => new Date(entry.clock_in_time) >= startDateObj
      );
    }
    if (endDate) {
      const newEndDate = new Date(endDate).getTime() + 24 * 60 * 60 * 1000;
      entriesArray = entriesArray.filter(({ clock_in_time }) => {
        return new Date(clock_in_time) < new Date(newEndDate);
      });
    }

    const tempRows = entriesArray.map((entry) => {
      return createData(
        formatDate(entry.clock_in_time),
        formatTime(entry.clock_in_time),
        formatTime(entry.clock_out_time),
        formatTimeDiff(entry.clock_out_time, entry.clock_in_time),
        <DownloadDetailIcon
          onClick={() => {
            setEntry(entry);
            setModal(true);
          }}
          style={{ cursor: "pointer" }}
        ></DownloadDetailIcon>,
        entry.isolation,
        entry.uuid
      );
    });
    setRows(tempRows);

    setRows((prevRows) =>
      prevRows.sort(
        (a, b) => new Date(b.name).getTime() - new Date(a.name).getTime()
      )
    );
  }, [employeeTimeSheetSubslice.res, startDate, endDate]);

  return (
    <>
      {modal && (
        <section
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              setModal(false);
            }
          }}
          className="timesheetModal"
        >
          <div className="timesheetModal__box">
            <TimeSheetMarkup entry={entry} />
          </div>
        </section>
      )}
      <div className="homepage">
        <SideBarMenu />
        <div className="homepage_container">
          <AppBar />
          <div className="homepage_content">
            <div className="time_sheet_details">
              <div className="header">
                <div
                  onClick={() => navigate(-1)}
                  className="cursor-pointer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <BackIcon className="cursor-pointer" />
                  <span>Timesheet</span>
                </div>
              </div>

              <div className="profile">
                <img
                  src={picture ? "data:image/jpeg;base64," + picture : Maya}
                />
                <div className="profile_detail">
                  <div>{`${employeeProfile?.first_name || "-"} ${
                    employeeProfile?.last_name || ""
                  }`}</div>
                  <span>{employeeProfile?.email || "-"}</span>
                </div>
                <Link
                  to={`/employee-details/${uuid}`}
                  className="text-deco-none"
                >
                  <button className="profile_detail_time_sheet_button cursor-pointer">
                    Employee Details
                  </button>
                </Link>
                <button
                  onClick={downloadTable}
                  style={{
                    padding: "17px 40px",
                    height: "48px",
                    background: "#4c8bf5",
                    borderRadius: "4px",
                    color: "#ffffff",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "16px",
                    cursor: "pointer",
                    marginLeft: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Download Table
                </button>
              </div>

              <div className="details_date_container">
                <div className="details_header">Details</div>
                <div className="date_container">
                  <DatePickerWrapper
                    value={startDate}
                    handleChange={(newValue) => setStartDate(newValue)}
                    maxDate={endDate && dayjs(endDate)}
                  />

                  <span>To </span>
                  <DatePickerWrapper
                    value={endDate}
                    handleChange={(newValue) => setEndDate(newValue)}
                    minDate={startDate}
                  />
                </div>
              </div>

              <TableContainer component={Paper} className="table_container">
                <Table aria-label="simple table" id="my-table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="left" className="details_table_cell">
                        <span>Date</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Time In</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Time Out</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Total Hours</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Isolation Patient</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Action</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeTimeSheetSubslice.status == "loading" && (
                      <td className="margin-center" colSpan="5">
                        <div className="loader margin"></div>
                      </td>
                    )}
                    {employeeTimeSheetSubslice.status !== "loading" &&
                      rows.length <= 0 && (
                        <div
                          style={{
                            width: "100%",
                            fontSize: "16px",
                            marginBlock: "18px",
                            marginLeft: "32px",
                          }}
                        >
                          No timesheet available!
                        </div>
                      )}
                    {employeeTimeSheetSubslice.status !== "loading" &&
                      rows.length > 0 &&
                      rows.map((row) => (
                        <TableRow key={row.uuid} className="table-row">
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.name}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.calories}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.fat}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.carbs}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.isolation ? "Yes" : "No"}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.protein}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function DownloadSingleTimsheet({ entry, DownloadDetailIcon }) {
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
      const maxWidth = 595.28; // A4 width in points (1/72 inch)
      const scaleFactor = maxWidth / reportTemplateRef.current.offsetWidth;

      const doc = new jsPDF({
        unit: "px",
        format: "a4",
      });

      // Adding the fonts
      // doc.setFont("Inter-Regular", "normal");

      doc.html(reportTemplateRef.current, {
        async callback(doc) {
          await doc.save("document");
        },
      });
    };

    return (
      <>
        <DownloadDetailIcon
          onClick={handleGeneratePdf}
          style={{ cursor: "pointer" }}
        ></DownloadDetailIcon>
        <div style={{ display: "none" }}>
          <div ref={reportTemplateRef}>
            <TimeSheetMarkup entry={entry} />
          </div>
        </div>
      </>
    );
  }

  function downloadTable() {
    const getPDFRows = () => {
      const pdfRows = rows.map((objRow) => {
        return [objRow.name, objRow.calories, objRow.fat, objRow.carbs];
      });
      return pdfRows;
    };

    const doc = new jsPDF();

    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    autoTable(doc, { html: ".MuiTable-root22" });

    autoTable(doc, {
      head: [["Date", "Time In", "Time Out", "Total Hours"]],
      body: getPDFRows(),
    });

    let currentDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(new Date());

    doc.save(`Employee Timesheet(${currentDate}).pdf`);
  }
};

export default TimeSheetPage;
