import React, { useState } from "react";
import "./CalendarItem.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { CloseModal } from "../../assets/images";
import { ConfirmDeleteModal } from "../confirmDeleteModal/confirmDeleteModal";

const CalendarItem = ({ date, data }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [action, setAction] = useState({});
  return (
    <div className="calendarItem">
      {deleteModalOpen && (
        <ConfirmDeleteModal
          action={action}
          setConfirmDeleteModalOpen={setDeleteModalOpen}
          message="Do you want to delete this shift?"
        />
      )}
      <div className="calendarItem__time">
        {date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })}
      </div>
      <div className="calendarItem__timeline">
        {Object.values(data[1]).map((d) => (
          <div style={{ position: "relative" }}>
            <AiOutlineCloseCircle
              className="delete-icon cursor-pointer"
              onClick={() => {
                setDeleteModalOpen(true),
                  setAction({
                    name: "deleteShift",
                    body: { shiftUUID: d.uuid, date },
                  });
              }}
              style={{ color: "rgba(0, 0, 255, 0.5)", width: "16px" }}
            />
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "10px",
                fontWeight: "600",
              }}
            >
              {d?.employee?.status === "Completed" && (
                <span
                  className="text-xs"
                  style={{
                    background: "#008000d9",
                    color: "white",
                    maxWidth: "fit-content",
                    borderRadius: "4px",
                    padding: "2px",
                    paddingInline: "4px",
                  }}
                >
                  {d?.employee?.status}
                </span>
              )}
              {d?.employee?.status === "Accepted" && (
                <span
                  className="text-xs"
                  style={{
                    background: "#5ca8ff",
                    color: "white",
                    maxWidth: "fit-content",
                    borderRadius: "4px",
                    padding: "2px",
                    paddingInline: "4px",
                  }}
                >
                  {d?.employee?.status}
                </span>
              )}
              {d?.employee?.status !== "Completed" &&
                d?.employee?.status !== "Accepted" && (
                  <span className="text-xs">
                    ({d?.employee?.status || "Created"})
                  </span>
                )}
              <span style={{ fontSize: "12px" }}>
                ${Number.parseInt(d?.rate_per_hour)}
              </span>
            </span>
            <span className="name">{d?.employee?.name || d?.position}</span>

            {/* <span className="location">{`${d?.location?.street1} ${d?.location?.city}, ${d?.location?.state}`}</span> */}
            <span className="location">
              {d?.facility_name} ({formatTimeRange(d?.start_time, d?.end_time)})
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarItem;

function formatTimeRange(start, end) {
  const options = { hour: "numeric", minute: "numeric" };
  const startTime = new Date(start).toLocaleTimeString("en-US", options);
  const endTime = new Date(end).toLocaleTimeString("en-US", options);
  return `${startTime}-${endTime}`;
}
