import React, { useEffect, useState } from "react";
import useCookies from "react-cookie/cjs/useCookies";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import RoutesComponent from "./Routes";
import { onMessageListener, takeToken } from "./firebase";

import "./styles/appStyles.scss";
import "./app.scss";

function App() {
  const [cookies, _, removeCookie] = useCookies(["token"]);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    if (cookies?.token?.access) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${cookies.token.access}`;

      const interceptor = axios.interceptors.request.use(
        async (config) => {
          const token = cookies?.token.access;
          const base64Url = token.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const decodedPayload = JSON.parse(window.atob(base64));

          if (new Date(decodedPayload.exp * 1000) - new Date(Date.now()) < 0) {
            removeCookie("token");
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      // Clean up interceptor
      return () => {
        axios.interceptors.request.eject(interceptor);
      };
    }
  }, [cookies]);

  useEffect(() => {
    takeToken(setTokenFound);

    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      })
      .catch((err) => console.log("failed: ", err));
    ;
  }, []);

  return (
    <>
      <RoutesComponent userType={cookies?.token?.user_type} />
      <ToastContainer />
    </>
  );
}

export default App;
