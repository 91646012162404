import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { NodLogo } from "../../assets/images";
import { ToggleButton } from "../../components";
import { fetchUserLogin } from "../../redux/userLoginSlice.js";
import { ReactComponent as MAIL } from "../../assets/images/mail.svg";
import { ReactComponent as LOCK } from "../../assets/images/lock-closed.svg";
import hidden from "../../assets/images/hidden.svg";

import { resetPassword } from "../../redux/userLoginSlice.js";
import SplashCarousel from "../../components/SplashCarousel/SplashCarousel";
import useCookies from "react-cookie/cjs/useCookies";

import "./loginPage.scss";

const LoginPage = () => {
  const [_, setCookie] = useCookies([]);
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    setCookie("rememberMe", rememberMe ? "1" : "0");
  }, [rememberMe]);

  const [activeLoginUserType, setActiveLoginUserType] = useState("Agency");

  const HandleLoginBtn = () => {
    setIsButtonDisabled(true);
    setLoginError("");
    dispatch(fetchUserLogin({ email, password, activeLoginUserType })).then((response) => {
      setIsButtonDisabled(false);
      if (response.error) return setLoginError(response.payload);

      setCookie(
        "token",
        {
          ...response.payload.token,
          user_type: response.payload.user_type,
        },
        {
          expires: 0,
        }
      );
    });
  };

  const forgotPaswordHandler = () => {
    if (!email) return setLoginError("please enter email");
    dispatch(resetPassword({ email })).then((data) => {
      data.meta.requestStatus == "fulfilled" && setLoginError("please check your Email");
    });
  };

  return (
    <div className="d-flex login align-center" style={{ background: "white" }}>
      <div className="d-flex login_description_page justify-center align-center">
        <div className="flex-column align-center">
          <div className="logo_icon">
            <NodLogo />
          </div>
          <SplashCarousel />
        </div>
      </div>

      <div className="d-flex login_container align-center justify-center">
        <div className="flex-column align_center justify_center">
          <div className="login_header">Login</div>

          <ToggleButton
            activeLoginUserType={activeLoginUserType}
            setActiveLoginUserType={setActiveLoginUserType}
            setPassword={setPassword}
            setEmail={setEmail}
          />

          <form className="form">
            <label className="box">
              <MAIL />
              <input
                value={email}
                className="form_email"
                type="email"
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="box">
              <LOCK />
              <input
                value={password}
                className="form_email"
                type={hidePassword ? "password" : "text"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={hidden}
                style={{ cursor: "pointer" }}
                onClick={() => setHidePassword(!hidePassword)}
              />
            </label>
            {loginError && <p className="login_page_error_text">{loginError}</p>}

            <div className="form_remember_me">
              <label className="d-flex">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="form_remember_me_text">Remember me</span>
              </label>
              {
                <a href="/forgot-password" className="form_forgot_password">
                  Forgot password?
                </a>
              }
            </div>
          </form>
          <button
            className="login_container_button"
            onClick={HandleLoginBtn}
            disabled={isButtonDisabled}
          >
            <span className="login_container_button_text">
              {isButtonDisabled ? "Loging In..." : "Login"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
