import React from "react";
import Rating from "@mui/material/Rating";
import { Maya } from "../../assets/images";

import "./reviewDetails.scss";

const reviewDetails = ({ comments, name, rating }) => {
  return (
    <div className="review_details">
      <div className="review_stars">
        <img src={Maya} alt="Dr. Maya" width="40px" height="40px" />
        <div className="review_content">
          <div>{name}</div>
          <Rating
            name="half-rating"
            value={rating}
            precision={0.5}
            className="Rating"
            readOnly
          />
        </div>
      </div>
      <div className="review_comments">{comments}</div>
    </div>
  );
};
export default reviewDetails;
