import React from "react";
import { OfficeBag } from "../../assets/images";

import "./employeeDetails.scss";

const EmployeeDetails = ({ icon, label, info }) => {
  info = info || "-";
  return (
    <div className="d-flex employee_info_container">
      <div className="icon" style={{ flexShrink: "0" }}>
        {icon}
      </div>
      <div className="flex-column employee-content">
        <label>{label}</label>
        <div>{info}</div>
      </div>
    </div>
  );
};
export default EmployeeDetails;
