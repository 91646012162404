import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearCreateShiftRequest } from "../../redux/shiftSlice";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import dayjs from "dayjs";
import { fetchAllEmployees } from "../../redux/employeeSlice";

import { CloseModal } from "../../assets/images";

import "./CreateNewShiftModal1.scss";
import { useCookies } from "react-cookie";
import { fetchAllFacilites } from "../../redux/agencySlice";
const CreateNewShiftModal1 = ({
  modal1Values,
  setModal1Open,
  setModal1Values,
  setModal2Open,
}) => {
  const dispatch = useDispatch();

  const [cookies] = useCookies(["token"]);

  const profile = useSelector((state) => state.userInfoSlice);
  const facilities = useSelector((state) => state.agencySlice.agencyFalities);

  const [error, setError] = useState();
  const [start, setStart] = useState(
    modal1Values?.startTime ? dayjs(modal1Values.startTime) : null
  );
  const [end, setEnd] = useState(
    modal1Values?.endTime ? dayjs(modal1Values.endTime) : null
  );
  const [input, setInput] = useState(modal1Values?.position);
  const [isolationPatients, setIsolationPatients] = useState(
    modal1Values?.isolationPatients
  );
  const [facility, setFacility] = useState(modal1Values?.facility || null);
  const [employee, setEmployee] = useState(modal1Values?.employee || null);

  const handleNextbtn = (e) => {
    e.preventDefault();

    setModal1Values({
      startTime: start && new Date(start.$d)?.toISOString(),
      endTime: end && new Date(end.$d)?.toISOString(),
      position: input,
      facility: facility,
      isolation_patient: isolationPatients,
      employee,
    });

    if (cookies?.token?.user_type === "Agency")
      if (!(input && start && end && facility))
        return setError("Please fill out necessary fields");

    if (!(input && start && end))
      return setError("Please fill out necessary fields");

    setModal1Open(false);
    setModal2Open(true);
  };

  // THIS CLEARS OUT REDUX'S CREATE SHIFT SLICE OF PREVIOUS CALL i.e this is done so that every time modal opens, previos data is deleted
  useEffect(() => {
    dispatch(clearCreateShiftRequest());
  }, []);

  // this is for only for agency
  useEffect(() => {
    if (cookies?.token?.user_type === "Agency" && profile) {
      dispatch(fetchAllFacilites(profile.res.profile.user.uuid));
      dispatch(fetchAllEmployees());
    }
  }, [profile]);
  const allEmployeeSubSlice = useSelector(
    (state) => state.employeeSlice.allEmployees
  );

  return (
    <div className="create-new-shift-modal-1-container">
      <div className="create-new-shift-modal-1">
        <div className="create-new-shift-modal-1__header">
          <h2>Create New Shift</h2>
          <div
            className="close-btn"
            onClick={() => {
              setModal1Open(false);
              setModal1Values({});
            }}
            style={{ cursor: "pointer" }}
          >
            <CloseModal />
          </div>
        </div>
        <h3>Please add details to post shift</h3>
        <form>
          <div className="grid">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Shift Start Time"
                minDateTime={dayjs(new Date())}
                maxDateTime={end}
                value={start}
                onChange={(newValue) => {
                  setStart(newValue);
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Shift End Time"
                value={end}
                minDateTime={start || dayjs(new Date())}
                onChange={(newValue) => {
                  setEnd(newValue);
                }}
              />
            </LocalizationProvider>

            <PositionSelect setInput={setInput} input={input} />
            <BasicSelect />
            {cookies?.token?.user_type === "Agency" && (
              <>
                <FacilitySelect
                  input={facility}
                  setInput={setFacility}
                  facilities={
                    (facilities.status === "success" &&
                      facilities.res.length > 0 &&
                      facilities.res) ||
                    (facilities.status === "loading" && [
                      {
                        uuid: "Loading Facilities Name",
                        name: "Loading Facilities Name",
                        disabled: 1,
                      },
                    ]) || [
                      facilities.status === "success" &&
                        facilities.res.length == 0 && {
                          uuid: "No Facility Found",
                          name: "No Facility Found",
                          disabled: 1,
                        },
                    ]
                  }
                />
                <EmployeeSelect
                  input={employee}
                  setInput={setEmployee}
                  employees={
                    (allEmployeeSubSlice.status === "success" &&
                      allEmployeeSubSlice.res.length > 0 &&
                      allEmployeeSubSlice.res) ||
                    (allEmployeeSubSlice.status === "loading" && [
                      {
                        uuid: "Loading-Employee-Names",
                        name: "Loading Employee Names",
                        disabled: 1,
                      },
                    ]) || [
                      allEmployeeSubSlice.status === "success" &&
                        allEmployeeSubSlice.res.length == 0 && {
                          uuid: "No-Facility-Found",
                          name: "No Employee Found",
                          disabled: 1,
                        },
                    ]
                  }
                />
              </>
            )}
          </div>
          <div
            className="error-div"
            style={{ color: "red", textAlign: "center", fontSize: "15px" }}
          >
            {error}
          </div>
          <div className="submit-wrapper">
            <button
              style={{ cursor: "pointer" }}
              type="submit"
              className="btn-next"
              onClick={(e) => handleNextbtn(e)}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  // MUI COMPONENTS FUNCTIONS
  function PositionSelect({ input, setInput }) {
    const handleChange = (event) => {
      setInput(event.target.value);
    };

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Position</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input}
            label="Isolation Patient"
            onChange={handleChange}
          >
            <MenuItem value={"Registered Nurse"}>Registered Nurse</MenuItem>
            <MenuItem value={"Licensed Practical Nurse"}>
              Licensed Practical Nurse
            </MenuItem>
            <MenuItem value={"Certified Nurse Assistant"}>
              Certified Nurse Assistant
            </MenuItem>
            <MenuItem value={"Certified Medication Aide"}>
              Certified Medication Aide
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

  // MUI COMPONENTS FUNCTIONS
  function FacilitySelect({ input, setInput, facilities }) {
    const handleChange = (event) => {
      setInput(event.target.value);
    };

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Facility</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input}
            label="Isolation Patient"
            onChange={handleChange}
          >
              
            {facilities.map((e) => (
              <MenuItem disabled={e.hasOwnProperty("disabled")} value={e.uuid}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
  // MUI COMPONENTS FUNCTIONS
  function EmployeeSelect({ input, setInput, employees }) {
    const handleChange = (event) => {
      setInput(event.target.value);
    };

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Employee</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={input}
            label="Isolation Patient"
            onChange={handleChange}
          >
          <MenuItem value={""}>
                Select Employee
              </MenuItem>
            {employees.map((e) => (
              <MenuItem
                disabled={e.hasOwnProperty("disabled")}
                value={e.uuid}
                key={e.uuid}
              >
                {e?.profile?.first_name
                  ? `${e?.profile?.first_name} ${e?.profile?.last_name}`
                  : e.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }

  // MUI COMPONENTS FUNCTIONS
  function BasicSelect() {
    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Isolation Patient
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={isolationPatients}
            label="Isolation Patient"
            onChange={(e) => setIsolationPatients(e.target.value)}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }
};

export default CreateNewShiftModal1;
