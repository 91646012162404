import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { CloseModal } from "../../assets/images";

import { createShiftCall } from "../../redux/shiftSlice";

import "./CreateNewShiftModal2.scss";
import { useCookies } from "react-cookie";
import { BackIcon } from "../../assets/images";

const CreateNewShiftModal2 = ({ modal1Values, setModal1Values, setModal2Open, setModal1Open }) => {
  const dispatch = useDispatch();
  const [rate, setRate] = useState(0);
  const [additionalDetails, setAdditionalDetails] = useState();
  const [error, setError] = useState();
  const [isPostShiftDisabled, setIsPostShiftDisabled] = useState(false);

  const uuid = useSelector((state) => state.userInfoSlice.res.profile.user.uuid);

  const createShiftSlice = useSelector((state) => state.shiftSlice.createShift);

  useEffect(() => {
    createShiftSlice.error?.message && setError(createShiftSlice.error?.message);
  }, [createShiftSlice.error]);

  const handlePostShift = () => {
    setIsPostShiftDisabled(true);
    const body = {
      start_time: modal1Values.startTime,
      end_time: modal1Values.endTime,
      position: modal1Values.position,
      isolation_patient: modal1Values.isolation_patient,
      rate_per_hour: rate,
      reward_points: 10,
      details: additionalDetails,
      facility: modal1Values?.facility ? modal1Values.facility : uuid,
      employee: modal1Values?.employee,
    };
    if (!rate) {
      setError("Please fill in the Rate per Hour field");
      setIsPostShiftDisabled(false);
      return;
    } else if(!additionalDetails){
      setError("Please fill in Additional details for the shift.");
      setIsPostShiftDisabled(false);
      return;
    }
    dispatch(createShiftCall(body));
  };

  if (createShiftSlice.status == "success") {
    return (
      <div className="create-new-shift-modal-1-container">
        <div
          className="create-new-shift-modal-2"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="close-btn"
            onClick={() => {
              setModal2Open(false);
              setModal1Values({});
            }}
            style={{ cursor: "pointer", right: "16px", position: "absolute" }}
          >
            <CloseModal />
          </div>
          <h2
            style={{
              color: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              flex: "1",
            }}
          >
            Shift Created successfully
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="create-new-shift-modal-1-container">
      <div className="create-new-shift-modal-2">
        <div className="create-new-shift-modal-2__header">
          <h2 className="flex items-center">
            <BackIcon
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                setModal2Open(false);
                setModal1Open(true);
              }}
            />
            Create New Shift
          </h2>

          <div
            className="close-btn"
            onClick={() => {
              setModal2Open(false);
              setModal1Values({});
            }}
            style={{ cursor: "pointer" }}
          >
            <CloseModal />
          </div>
        </div>
        <h3>Please Select the rate</h3>
        <div className="inputs-wrapper">
          <div
            className="textarea"
            onChange={(e) => setAdditionalDetails(e.target.value)}
            name="additional_datails"
            rows="5"
            cols="50"
          >
            <textarea type="text" placeholder="Add additional details" />
          </div>
          <div className="flex-column">
            <div className="rate-container">
              <label>
                <span>Type Rate</span>
                <div className="input-rate-wrapper">
                  <input
                    type="number"
                    onChange={(e) => setRate(e.target.value)}
                    className="rate-input"
                    // value="32"
                    value={rate}
                  />
                </div>
              </label>
            </div>
            <button
              className="btn-next"
              onClick={handlePostShift}
              style={{ cursor: "pointer" }}
              disabled={isPostShiftDisabled }
            >
              {isPostShiftDisabled ? "Posting Shift..." : "Post Shift"}
            </button>
          </div>
        </div>
        <div className="error-div" style={{ color: "red", textAlign: "center", fontSize: "15px" }}>
          {error}
        </div>
      </div>
    </div>
  );
};

export default CreateNewShiftModal2;
