import React from "react";
import { useDispatch } from "react-redux";
import "./confirmDeleteModal.scss";
import { patchShiftRequest } from "../../redux/shiftSlice";
import { deleteEmployee } from "../../redux/employeeSlice";
import { fetchAllEmployees } from "../../redux/employeeSlice";
import { deleteAgency } from "../../redux/agencySlice";
import { deleteFacility } from "../../redux/facilitySlice";
import { deleteShift } from "../../redux/shiftSlice";
import { fetchCreatedShifts } from "../../redux/shiftSlice";
import { useState } from "react";
import { getMonthBoundaries } from "../../utils/getMonthBoundaries";

export const ConfirmDeleteModal = ({
  action,
  setConfirmDeleteModalOpen,
  message,
}) => {
  const [isYesDisabled, setIsYesDisabled] = useState(false);
  const { name, body } = action;
  const dispatch = useDispatch();

  const rejectHandler = () => {
    setIsYesDisabled(true);
    if (name == "patchShiftRequest")
      dispatch(patchShiftRequest(body)).then((data) => {
        if (data?.error?.code === "ERR_BAD_REQUEST") {
          alert("A request for this shift has already been accepted.");
          setConfirmDeleteModalOpen(false);
          setIsYesDisabled(false);
          return;
        }
        if (action?.handleRefetch) {
          action.handleRefetch();
        }
        setConfirmDeleteModalOpen(false);
        setIsYesDisabled(false);
      });
    if (name == "deleteAgency")
      dispatch(deleteAgency(body)).then((data) => {
        data.meta.requestStatus == "fulfilled" &&
          setConfirmDeleteModalOpen(false);
        setIsYesDisabled(false);
      });

    if (name == "deleteFacility")
      dispatch(deleteFacility(body)).then((data) => {
        data.meta.requestStatus == "fulfilled" &&
          setConfirmDeleteModalOpen(false);
        setIsYesDisabled(false);
      });

    if (name == "deleteEmployee")
      dispatch(deleteEmployee(body.employeeUUID)).then((data) => {
        if (data.meta.requestStatus == "fulfilled") {
          setConfirmDeleteModalOpen(false);
        }
        setIsYesDisabled(false);
      });

    if (name === "deleteShift")
      dispatch(deleteShift(body.shiftUUID)).then((data) => {
        const { fromDate, toDate } = getMonthBoundaries(
          body.date.getFullYear(),
          body.date.getMonth() + 1
        );
        dispatch(fetchCreatedShifts({ fromDate, toDate }));
        setConfirmDeleteModalOpen(false);
        setIsYesDisabled(false);
      });
  };

  return (
    <div className="confirm-delete-modal-wrapper">
      <div className="confirm-delete-modal">
        <div className="confirm-text">{message}</div>
        <div className="buttons">
          <button
            className="button reject cursor-pointer"
            onClick={rejectHandler}
            disabled={isYesDisabled}
          >
            Yes
          </button>
          <button
            className="button accept cursor-pointer"
            onClick={() => setConfirmDeleteModalOpen(false)}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};
