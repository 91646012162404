import React, { useEffect, useRef, useState } from "react";
import { BackIcon, Maya, DownloadDetailIcon } from "../../assets/images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { DatePickerWrapper, SideBarMenu, AppBar } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchAllTimesheet } from "../../redux/shiftSlice";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { TimeSheetMarkup } from "../../components/TimeSheetMarkup/TimeSheetMarkup";

import {
  formatDate,
  formatTime,
  formatTimeDiff,
} from "../../utils/timesheetUtils";

import "./AllTimeSheetPage.scss";

// import { Display } from "html2canvas/dist/types/css/property-descriptors/display";

const AllTimeSheetPage = () => {
  const navigate = useNavigate(-1);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const allTimesheet = useSelector((state) => state.shiftSlice.allTimeSheet);
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    dispatch(fetchAllTimesheet());
  }, []);

  const [rows, setRows] = useState([]);

  function createData(
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
    isolation,
    uuid
  ) {
    return { id, name, calories, fat, carbs, protein, isolation, uuid };
  }

  useEffect(() => {
    if (allTimesheet.status === "success") {
      const tempRows = [...allTimesheet.res].map((entry) => {
        return createData(
          entry["Employee First Name"] + " " + entry["Employee Last Name"],
          formatDate(entry.clock_in_time),
          formatTime(entry.clock_in_time),
          formatTime(entry.clock_out_time),
          formatTimeDiff(entry.clock_out_time, entry.clock_in_time),
          <DownloadDetailIcon
            onClick={() => {
              setEntry(entry);
              setModal(true);
            }}
            style={{ cursor: "pointer" }}
          ></DownloadDetailIcon>,
          // <DownloadSingleTimsheet
          //   entry={entry}
          //   DownloadDetailIcon={DownloadDetailIcon}
          // />,
          entry.isolation,
          entry.uuid
        );
      });
      setRows(tempRows);

      setRows((prevRows) =>
        prevRows.sort((a, b) => {
          return Date.parse(b.name) - Date.parse(a.name);
        })
      );
    }
  }, [allTimesheet]);

  return (
    <>
      {modal && (
        <section
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              setModal(false);
            }
          }}
          className="timesheetModal"
        >
          <div className="timesheetModal__box">
            <TimeSheetMarkup entry={entry} />
          </div>
        </section>
      )}
      <div className="homepage">
        <SideBarMenu />
        <div className="homepage_container">
          <AppBar />
          <div className="homepage_content">
            <div className="time_sheet_details">
              <div
                className="header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => navigate(-1)}
                  className="cursor-pointer flex align-center"
                >
                  <BackIcon />
                  <span>Back</span>
                </div>
                <button
                  onClick={downloadTable}
                  style={{
                    padding: "17px 40px",
                    height: "48px",
                    background: "#4c8bf5",
                    borderRadius: "4px",
                    color: "#ffffff",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "16px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Download Table
                </button>
              </div>

              <TableContainer component={Paper} className="table_container">
                <Table aria-label="simple table">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell align="left" className="details_table_cell">
                        <span>Name</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Date</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Time In</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Time Out</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Total Hours</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Isolation Patient</span>
                      </TableCell>
                      <TableCell align="left" className="details_table_cell">
                        <span>Action</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allTimesheet.status == "loading" ? (
                      <td className="margin-center" colSpan="7">
                        <div className="loader margin"></div>
                      </td>
                    ) : (
                      rows.map((row) => (
                        <TableRow key={row.uuid} className="table-row">
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span style={{ textTransform: "capitalize" }}>
                              {row.id ? row.id : "-"}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.name}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.calories}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.fat}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.carbs}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.isolation ? "Yes" : "No"}</span>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="details_table_cell"
                          >
                            <span>{row.protein}</span>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function downloadTable() {
    const getPDFRows = () => {
      const pdfRows = rows.map((objRow) => {
        return [
          objRow.id ? objRow.id : "-",
          objRow.name,
          objRow.calories,
          objRow.fat,
          objRow.carbs,
        ];
      });
      return pdfRows;
    };

    const doc = new jsPDF();

    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    autoTable(doc, { html: ".MuiTable-root22" });

    autoTable(doc, {
      head: [["Name", "Date", "Time In", "Time Out", "Total Hours"]],
      body: getPDFRows(),
    });

    let currentDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(new Date());
    doc.save(`Facility Timesheet(${currentDate}).pdf`);
  }
};

function DownloadSingleTimsheet({ entry, DownloadDetailIcon }) {
  const reportTemplateRef = useRef(null);

  const handleGeneratePdf = () => {
    const maxWidth = 595.28; // A4 width in points (1/72 inch)
    const scaleFactor = maxWidth / reportTemplateRef.current.offsetWidth;

    const doc = new jsPDF({
      unit: "px",
      format: "a4",
    });

    // Adding the fonts
    doc.setFont("Inter", "normal");

    doc.html(reportTemplateRef.current, {
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  return (
    <>
      <DownloadDetailIcon
        onClick={handleGeneratePdf}
        style={{ cursor: "pointer" }}
      ></DownloadDetailIcon>
      <div style={{ display: "none" }}>
        <div ref={reportTemplateRef}>
          <TimeSheetMarkup entry={entry} />
        </div>
      </div>
    </>
  );
}

export default AllTimeSheetPage;
