import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Hidden from "../../assets/images/hidden.svg";
import "./ChangePassword.scss";
import axios from "axios";
import { NodLogo } from "../../assets/images";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hidden, setHidden] = useState([false, false]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Make sure that both passwords match!");
    } else if (confirmPassword.length < 8)
      return setError("Password must contain at least 8 characters");
    else if (!(/[A-Z]/.test(confirmPassword) && /\d/.test(confirmPassword)))
      return setError(
        "Password must contain at least one uppercase letter and one number"
      );
    else {
      try {
        const res = await axios.post(
          "https://backend-v2.thenursebridge.com/auth/password-resetconfirm/",
          {
            password: password,
            token: query.get("token"),
          }
        );
        if (res.status === 200) {
          setPasswordChanged(true);
        }
      } catch (err) {
        setError(err.response.data.password[0]);
      }
    }
  };
  return (
    <div className="resetPassword">
      <NodLogo />
      {!passwordChanged ? (
        <>
          <div>Reset your password</div>
          <form onSubmit={handleSubmit}>
            <label>
              <span>New password</span>
              <section>
                <input
                  type={hidden[0] ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                />
                <img
                  src={Hidden}
                  onClick={() => setHidden([!hidden[0], hidden[1]])}
                  alt=""
                />
              </section>
            </label>
            <label>
              <span>Confirm password</span>
              <section>
                <input
                  type={hidden[1] ? "text" : "password"}
                  value={confirmPassword}
                  placeholder="Enter confirm password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  src={Hidden}
                  onClick={() => setHidden([hidden[0], !hidden[1]])}
                  alt=""
                />
              </section>
            </label>
            {error && <p>{error}</p>}
            <button type="submit">Reset my password</button>
          </form>
        </>
      ) : (
        <div className="changed">Your password is successfully changed</div>
      )}
    </div>
  );
};

export default ChangePassword;
