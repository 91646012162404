// a lot of markup on this page is from "/employee-application"
import { AppBar, SideBarMenu } from "../../components";

import {
  PlusIcon,
  SeenIcon,
  BlockIcon,
  TableFieldImg,
  DropdownIcon,
  TrueCheck,
} from "../../assets/images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import { AddNewFacilityModal } from "../../components/addNewFacilityModal/addNewFacilityModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllFacilities } from "../../redux/facilitySlice";
import { Link } from "react-router-dom";

import { Select, MenuItem } from "@mui/material";
import { deleteFacility } from "../../redux/facilitySlice";
import { ConfirmDeleteModal } from "../../components/confirmDeleteModal/confirmDeleteModal";

import "./facilityAppliationContainer.scss";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";

export const FacilityApplicationContainer = ({ select, setSelect }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [deleteFacilityModalOpen, setDeleteFacilityModalOpen] = useState(false);
  const [deleteAction, setDeleteAction] = useState();

  function createData(name, email, actionIcons, uuid, protein) {
    return { name, email, actionIcons, uuid, protein };
  }

  useEffect(() => {
    dispatch(fetchAllFacilities()).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
      }
    });
  }, []);

  const facilityApplicationSubSlice = useSelector(
    (state) => state.facilitySlice.allFacilities
  );

  useEffect(() => {
    facilityApplicationSubSlice.status === "success" &&
      setRows(
        facilityApplicationSubSlice.res.map((facility) => {
          return createData(
            <div className="table-field-img-row">{facility.name}</div>,
            facility.email,
            <div className="action-icons">
              <Link to={`/facility-details/${facility.uuid}`}>
                <SeenIcon />
              </Link>

              {/* <TrueCheck /> */}
              <BlockIcon
                className="cursor-pointer"
                onClick={() => {
                  setDeleteFacilityModalOpen(true);
                  setDeleteAction({
                    name: "deleteFacility",
                    body: facility.uuid,
                  });
                }}
              />
            </div>,
            facility.uuid
          );
        })
      );
  }, [facilityApplicationSubSlice]);

  return (
    <div className="homepage">
      {deleteFacilityModalOpen && (
        <ConfirmDeleteModal
          setConfirmDeleteModalOpen={setDeleteFacilityModalOpen}
          message="Do you want to delete this facility?"
          action={deleteAction}
        />
      )}
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          {modalOpen && <AddNewFacilityModal setModalOpen={setModalOpen} />}
          <div className="employee-list-container">
            <div className="employee-list">
              <div className="employee-label-container">
                <div className="employee-label">Facilities</div>
                <div className="employee-subtitle">Active Facilities</div>
              </div>
              <div className="create-employee-btn-container">
                <div className="create-employee-btn">
                  <PlusIcon />
                  <div
                    className="create-employee-text"
                    onClick={() => setModalOpen(true)}
                    style={{ cursor: "pointer" }}
                  >
                    Add Facility
                  </div>
                </div>

                <Select
                  className="employee-dropdown"
                  value={select}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                  <MenuItem value={"Facility"}>Facility</MenuItem>
                </Select>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className="employee_list_table_container"
              style={{ height: "unset" }}
            >
              <Table aria-label="simple table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell align="left" className="details_table_cell">
                      <span>Name</span>
                    </TableCell>
                    <TableCell align="left" className="details_table_cell">
                      <span>Email Address</span>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="details_table_cell details_table_cell2 "
                    >
                      <span>Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {facilityApplicationSubSlice?.status == "loading" && (
                    <td className="margin-center" colSpan="5">
                      <div className="loader margin"></div>
                    </td>
                  )}

                  {facilityApplicationSubSlice?.status == "success" &&
                    facilityApplicationSubSlice?.res?.length > 0 &&
                    rows.slice((pageNo - 1) * 10, pageNo * 10).map((row) => (
                      <TableRow key={row.uuid} className="table-row">
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row.name}</span>
                        </TableCell>
                        <TableCell align="left" className="details_table_cell">
                          <span className="field-text">{row.email}</span>
                        </TableCell>
                        <TableCell
                          align="left"
                          className="details_table_cell details_table_cell2"
                        >
                          <span className="field-text">{row.actionIcons}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  {facilityApplicationSubSlice?.status == "success" &&
                    facilityApplicationSubSlice?.res?.length == 0 && (
                      <TableCell colSpan={3}>
                        <span>No active Facility</span>
                      </TableCell>
                    )}
                </TableBody>
              </Table>
            </TableContainer>

            <PaginationComponent
              rows={rows}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FacilityApplicationContainer;
