import React from "react";
import {
  BasicLife,
  Covid,
  Flu,
  Heart,
  Hp,
  MMR,
  ResumeIcon,
  TB,
} from "../../../../assets/images";
import { DocumentDetails } from "../../../../components";
import "./documentsContainer.scss";

const DocumentsContainer = ({ employeeProfileRes }) => {
  return (
    <div className="document-info">
      <div className="d-flex document-content-gap" style={{ flexWrap: "wrap" }}>
        <div className="flex-column document-content">
          <DocumentDetails
            fileName="RESUME"
            icon={<ResumeIcon />}
            label="Resume"
          />

          <DocumentDetails
            fileName="TB PPD RECORDS"
            icon={<TB />}
            label="TB/PPD Records"
          />
          <DocumentDetails
            fileName="COVID VACCINE"
            icon={<Covid />}
            label="COVID vaccine"
          />
        </div>
        <div className="flex-column document-content">
          <DocumentDetails
            fileName="PHYSICAL ASSESSMENT"
            icon={<Heart />}
            label="Physical assessment "
          />
          <DocumentDetails
            fileName="HEPATITIS VACCINE"
            icon={<Hp />}
            label="Hepatitis vaccine"
          />
          <DocumentDetails
            fileName="FLU VACCINE"
            icon={<Flu />}
            label="Flu vaccine"
          />
        </div>
        <div className="flex-column document-content">
          <DocumentDetails
            fileName="BASIC LIFE SUPPORT"
            icon={<BasicLife />}
            label="Basic life support "
          />
          <DocumentDetails
            fileName="MMR AND VARICELLA VACCINE"
            icon={<MMR />}
            label="MMR and Varicella Vaccine"
          />
          <DocumentDetails
            fileName="DRIVING LICENCE"
            icon={<ResumeIcon />}
            label="Driving Licence"
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentsContainer;
