import React, { useEffect, useState } from "react";
import { NodLogo } from "../../assets/images";

import { useDispatch } from "react-redux";
import { fetchAdminLogin } from "../../redux/userLoginSlice.js";
import hidden from "../../assets/images/hidden.svg";

import { ReactComponent as MAIL } from "../../assets/images/mail.svg";
import { ReactComponent as LOCK } from "../../assets/images/lock-closed.svg";
import useCookies from "react-cookie/cjs/useCookies";
import SplashCarousel from "../../components/SplashCarousel/SplashCarousel";
import { Link } from "react-router-dom";

const AdminLoginPage = () => {
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [hidePassword, setHidePassword] = useState(true);
  const [_, setCookie] = useCookies([]);

  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    setCookie("rememberMe", rememberMe ? "1" : "0");
  }, [rememberMe]);

  const HandleLoginBtn = () => {
    dispatch(fetchAdminLogin({ email, password })).then((response) => {
      if (response.error) return setLoginError(response.payload);
      setCookie("token", {
        ...response.payload.token,
        user_type: "Admin",
      });
    });
  };

  return (
    <div className="d-flex login align-center" style={{ background: "white" }}>
      <div className="d-flex login_description_page justify-center align-center">
        <div className="flex-column align-center">
          <div className="logo_icon">
            <NodLogo />
          </div>
          <SplashCarousel />
        </div>
      </div>

      <div className="d-flex login_container align-center justify-center">
        <div className="flex-column align_center justify_center">
          <div className="login_header">Login</div>

          <form className="form">
            <label className="box">
              <MAIL />
              <input
                className="form_email"
                type="email"
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label className="box">
              <LOCK />
              <input
                className="form_email"
                type={hidePassword ? "password" : "text"}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={hidden}
                style={{ cursor: "pointer" }}
                onClick={() => setHidePassword(!hidePassword)}
              />
            </label>
            {loginError && (
              <p className="login_page_error_text">{loginError}</p>
            )}

            <div className="form_remember_me">
              <label className="d-flex">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span className="form_remember_me_text">Remember me</span>
              </label>
              {
                <Link to="/forgot-password" className="form_forgot_password">
                  Forgot password?
                </Link>
              }
            </div>
          </form>

          <button className="login_container_button" onClick={HandleLoginBtn}>
            <span className="login_container_button_text">Login</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
