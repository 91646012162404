import React, { useEffect, useState } from "react";
import "./CalendarPage.scss";
import { AppBar, SideBarMenu } from "../../components";
import { BackIcon } from "../../assets/images";
import CalendarItem from "../../components/calendarItem/CalendarItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreatedShifts } from "../../redux/shiftSlice";
import { useNavigate } from "react-router-dom";
import { monthData } from "./dataShort.js";
import { getMonthBoundaries } from "../../utils/getMonthBoundaries";
export function MyDateRangePicker() {
  const [value, setValue] = React.useState([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        calendars="1"
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
    </LocalizationProvider>
  );
}

const CalendarPage = () => {
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [groupedData, setGroupedData] = useState({});
  const [selected, setSelected] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calendar = useSelector((state) => state.shiftSlice.createdShifts);

  // Events
  function handleBackMonth() {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  }

  function handleNextMonth() {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  }

  useEffect(() => {
    const { fromDate, toDate } = getMonthBoundaries(year, month);
    dispatch(fetchCreatedShifts({ fromDate, toDate }));
  }, [month, year]);

  useEffect(() => {
    if (calendar.status === "success") {
      let grouped = calendar?.res?.reduce(
        (acc, el) => {
          if (!acc[new Date(el.start_time).toLocaleDateString("en-us")])
            acc[new Date(el.start_time).toLocaleDateString("en-us")] = [];
          acc[new Date(el.start_time).toLocaleDateString("en-us")].push(el);
          return acc;
        },
        generateMonthCalendar2(month, year),
      );

      setGroupedData(grouped);

      let groupedTime = Object.keys(grouped).map((date) => {
        return grouped[date].reduce((acc, el) => {
          if (!acc[new Date(el.start_time).toLocaleString("en-us")]) {
            acc[new Date(el.start_time).toLocaleString("en-us")] = [];
          }
          acc[new Date(el.start_time).toLocaleString("en-us")].push(el);

          return acc;
        }, {});
      });

      groupedTime.forEach(
        (e, ind) =>
          (grouped[new Date(Object.keys(e)[0]).toLocaleDateString("en-us")] =
            e),
      );

      const keys = Object.keys(grouped);
      if (!(selected && Object.keys(grouped).includes(selected)))
        setSelected(keys[0]);
    }
  }, [calendar, month, year]);

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="calendar">
            <div
              className="calendar__back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <BackIcon />
              Back
            </div>
            <div className="calendar__month-container">
              <section>
                <div
                  className="d-flex align-center justify-between"
                  style={{ gap: "16px", minWidth: "120px" }}
                >
                  <BackIcon onClick={handleBackMonth} />
                  <span>
                    {monthData[month]} {year}
                  </span>
                  <BackIcon onClick={handleNextMonth} />
                </div>
              </section>
            </div>
            {calendar.status == "loading" &&
              Object.keys(groupedData).length <= 0 && (
                <div style={{ marginTop: 64 }} className="loader margin"></div>
              )}

            {Object.keys(groupedData).length > 0 && (
              <>
                {Object.keys(groupedData).length > 0 && (
                  <>
                    <div className="calendar__date-container">
                      {groupedData &&
                        Object.keys(groupedData)
                          .slice(0, -1)
                          .map((e) => (
                            <section
                              onClick={() => setSelected(e)}
                              className={`${selected === e ? "active" : ""}`}
                            >
                              {new Date(e).toLocaleString("default", {
                                day: "numeric",
                                month: "short",
                              })}
                            </section>
                          ))}
                    </div>
                    <main>
                      {groupedData &&
                        Object.keys(groupedData).length !== 0 &&
                        Object.entries(groupedData[selected]).map((data) => {
                          return (
                            <CalendarItem
                              date={new Date(data[0])}
                              data={data}
                            />
                          );
                        })}
                    </main>
                    {Object.entries(groupedData[selected]).length === 0 && (
                      <div className="no-data text-xl mt-[5vh] flex justify-center">
                        No shift found
                      </div>
                    )}
                  </>
                )}
                {/* {Object.keys(groupedData).length == 0 && (
                  <div className="no-data text-xl mt-[15vh] flex justify-center">No shift found</div>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;

function generateMonthCalendar(month, year) {
  const calendar = {};

  // Get the number of days in the given month
  const numDays = new Date(year, month, 0).getDate();

  // Loop through each day of the month and add an empty object to the calendar object
  for (let day = 1; day <= numDays; day++) {
    const dateStr = `${month}/${day}/${year}`;
    calendar[dateStr] = {};
  }

  return calendar;
}

function generateMonthCalendar2(month, year) {
  const calendar = {};

  // Get the number of days in the given month
  const numDays = new Date(year, month, 0).getDate();

  // Loop through each day of the month and add an empty array to the calendar object
  for (let day = 1; day <= numDays; day++) {
    const dateStr = `${month}/${day}/${year}`;
    calendar[dateStr] = [];
  }

  return calendar;
}
