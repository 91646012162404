import React from "react";
import { CloseIcon, ProfileSnap, Maya } from "../../assets/images";

import { Link } from "react-router-dom";

import "./profileCard.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getImage } from "../../utils/getAwsFile";

const ProfileCard = ({ employee, img }) => {
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (img) {
      getImage(img).then((data) => {
        setPicture(data);
      });
      // setPicture(img);
    }
  }, [img]);

  return (
    <div className="profile_container">
      {picture ? (
        <div
          className="img"
          style={{
            backgroundImage: `url(${
              img ? "data:image/jpeg;base64," + picture : Maya
            })`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "100%",
            // height: "100%",
          }}
        ></div>
      ) : (
        <section
          style={{
            height: 200,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <div className="loader margin"></div>
        </section>
      )}

      <div className="profile_content">
        <div>
          {employee?.profile?.first_name
            ? `${employee?.profile?.first_name} ${employee?.profile?.last_name}`
            : "Maya"}
        </div>
        <p>{employee?.about}</p>
        <Link to={`/employee-details/${employee?.uuid}`}>
          <button>Check Profile</button>
        </Link>
      </div>
    </div>
  );
};

export default ProfileCard;
