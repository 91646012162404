import React, { useEffect, useState } from "react";
import { BackIcon, Maya } from "../../assets/images";

import { SideBarMenu, AppBar } from "../../components";
import "./agencySettingsContainer.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAgencyProfile } from "../../redux/agencySlice";
import { EditAgencyModal } from "../../components/editAgencyModal/editAgencyModal";

const AgencySettingsContainer = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editAgencyModalOpen, setEditAgencyModalOpen] = useState(false);
  const [editingAgencyUUID, setEditingAgencyUUID] = useState();

  useEffect(() => {
    dispatch(fetchAgencyProfile({ uuid }));
  }, []);

  const agencyProfileSubSlice = useSelector(
    (state) => state.agencySlice.Profile
  );

  useEffect(() => {
    agencyProfileSubSlice.status === "success" && setLoading(false);
  }, [agencyProfileSubSlice.status]);

  return (
    <div className="homepage">
      {editAgencyModalOpen && (
        <EditAgencyModal
          uuid={editingAgencyUUID}
          setEditAgencyModalOpen={setEditAgencyModalOpen}
        />
      )}
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="agency_setting_details">
            <div className="header">
              <BackIcon
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span>Agency Setting</span>
            </div>
            {loading == true && (
              <div
                style={{
                  marginTop: 64,
                  transform: "translateX(-50%)",
                  position: "relative",
                  left: "50%",
                }}
                className="loader margin"
              ></div>
            )}

            {loading == false && (
              <div className="agency">
                <img src={Maya} />
                <div className="agency_detail">
                  <div>{agencyProfileSubSlice?.res?.name}</div>
                  <span>
                    {agencyProfileSubSlice?.res?.profile?.user?.email}
                  </span>
                </div>
                <button
                  className="agency_detail_button cursor-pointer"
                  onClick={() => {
                    setEditingAgencyUUID(
                      agencyProfileSubSlice.res.profile.user.uuid
                    );
                    setEditAgencyModalOpen(true);
                  }}
                >
                  <span>Edit</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencySettingsContainer;
