import React, { useEffect, useState } from "react";
import "./changeImage.scss";
import { patchUserImages } from "../../redux/userInfoSlice";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { Navigate, useNavigate } from "react-router-dom";

export const ChangeImage = () => {
  const [images, setImages] = useState([]);
  const [imageURLs, setImageURLs] = useState([]);
  const [base64Imgs, setBase64Imgs] = useState([]);

  const [cookies] = useCookies();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (images.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImageURLs(newImageURLs);
  }, [images]);

  const onImageChange = function (e) {
    let fileTooBig;
    [...e.target.files].map(
      (file) => file.size > 2097152 && (fileTooBig = true)
    );
    if (fileTooBig) {
      alert("file bigger than 2mb");
      return;
    }
    setImages([...e.target.files]);
    [...e.target.files].map((img, index) => {
      index == "0" && setBase64Imgs([]);
      getBase64(img, (result) => {
        setBase64Imgs((prevImages) => [
          ...prevImages,
          {
            type: "IMAGE",
            file: result.replace(/^data:image\/\w+;base64,/, ""),
          },
        ]);
      });
    });
  };

  return (
    <div className="change-image-container">
      <h1 style={{ marginBottom: "2rem" }}>Change Image</h1>
      <label className="upload-image cursor-pointer">
        <>
          <div className="svg-wrapper">
            {/* prettier-ignore */}
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.600098 18.4004C0.600098 17.7376 1.13736 17.2004 1.8001 17.2004H16.2001C16.8628 17.2004 17.4001 17.7376 17.4001 18.4004C17.4001 19.0631 16.8628 19.6004 16.2001 19.6004H1.8001C1.13736 19.6004 0.600098 19.0631 0.600098 18.4004ZM4.55157 6.04892C4.08294 5.58029 4.08294 4.82049 4.55157 4.35186L8.15157 0.751863C8.37661 0.526819 8.68184 0.400391 9.0001 0.400391C9.31836 0.400391 9.62358 0.526819 9.84862 0.751863L13.4486 4.35186C13.9173 4.82049 13.9173 5.58029 13.4486 6.04892C12.98 6.51755 12.2202 6.51755 11.7516 6.04892L10.2001 4.49745L10.2001 13.6004C10.2001 14.2631 9.66284 14.8004 9.0001 14.8004C8.33735 14.8004 7.8001 14.2631 7.8001 13.6004L7.8001 4.49745L6.24863 6.04892C5.78 6.51755 5.0202 6.51755 4.55157 6.04892Z" fill="#A7B6D1"/>
</svg>
          </div>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={onImageChange}
            style={{ display: "none" }}
            onClick={() => setBase64Imgs([])}
          ></input>
          <div className="pseudo-btn">
            <span>Drag and drop or&nbsp;</span>

            <span style={{ color: "#4C8BF5" }}>browse file</span>
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                marginBottom: "0.5rem",
                marginTop: "1rem",
              }}
            >
              {imageURLs.length > 0 && `${imageURLs.length} selected`}
            </div>
          </div>
        </>
      </label>

      <div className="selected-images">
        {imageURLs.map((imageSrc, index) => (
          <div className="selected-image-div">
            <span>{index + 1} : </span>
            <img
              src={imageSrc}
              className="selected-img"
              style={{ maxWidth: "80%" }}
            />
          </div>
        ))}
      </div>
      <button
        className="add-facility-btn cursor-pointer"
        onClick={clickHandler}
      >
        Save to profile
      </button>
    </div>
  );
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
    };
  }
  function clickHandler() {
    dispatch(patchUserImages({ token: cookies.token, files: base64Imgs })).then(
      (res) => {
        if (res.type === "patchUserImages/fulfilled") navigate("/profile");
      }
    );
  }
};
