import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { NodLogo } from "../../assets/images";

import { ReactComponent as MAIL } from "../../assets/images/mail.svg";

import { resetPassword } from "../../redux/userLoginSlice.js";
import SplashCarousel from "../../components/SplashCarousel/SplashCarousel";

// import "./loginPage.scss";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const [passwordChangeMessage, setpasswordChangeMessage] = useState();
  const [passwordChangeError, setpasswordChangeError] = useState();
  const [email, setEmail] = useState();

  const forgotPaswordHandler = () => {
    setpasswordChangeMessage("");
    setpasswordChangeError("");
    if (!email) return setpasswordChangeMessage("please enter email");
    dispatch(resetPassword({ email })).then((data) => {

      data.meta.requestStatus == "fulfilled" &&
        setpasswordChangeMessage("please check your Email");

      data.meta.requestStatus == "rejected" &&
        setpasswordChangeError(data.payload);
    });
  };

  return (
    <div className="d-flex login align-center" style={{ background: "white" }}>
      <div className="d-flex login_description_page justify-center align-center">
        <div className="flex-column align-center">
          <div className="logo_icon">
            <NodLogo />
          </div>
          <SplashCarousel />
        </div>
      </div>

      <div className="d-flex login_container align-center justify-center">
        <div className="flex-column align_center justify_center">
          <div className="login_header" style={{ marginBottom: "0px" }}>
            Forgot Password
          </div>
          <form className="form">
            <label className="box">
              <MAIL />
              <input
                value={email}
                className="form_email"
                type="email"
                placeholder="Your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </form>
          {passwordChangeMessage && (
            <p
              className="login_page_error_text"
              style={{ color: "green", fontSize: "14px" }}
            >
              {passwordChangeMessage}
            </p>
          )}
          {passwordChangeError && (
            <p
              className="login_page_error_text"
              style={{
                color: "red",
                fontSize: "14px",
                width: "343px",
                textAlign: "center",
              }}
            >
              {passwordChangeError}
            </p>
          )}

          <button
            className="login_container_button"
            onClick={forgotPaswordHandler}
            style={{ marginTop: "0px" }}
          >
            <span className="login_container_button_text">
              {passwordChangeMessage ? "Resend Email" : "Proceed"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
