import { Agency } from "../../assets/images";
import "./agencyCountCard.scss";
import { Link } from "react-router-dom";

const AgencyCountCard = ({ totalAgenciesCount }) => {
  return (
    <div className="agency-count">
      <div className="agency-header-box">
        <Agency />
        <div className="total-agencies-label">Total Agencies</div>
      </div>
      <div className="agency-count-container">
        <div className="count">{totalAgenciesCount}</div>
        <Link
          to="/agency-list"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          <div className="view-all-btn">
            <span>View All</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AgencyCountCard;
