import React, { useEffect, useState } from "react";
import { Alice, FilterIcon, Maya, NotificationIcon, SearchIcon } from "../../assets/images";
import { NotificationPage } from "../../containers";
import "./appBar.scss";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { getImage } from "../../utils/getAwsFile";

import { updateProfilePicture } from "../../redux/userInfoSlice";
import useCookies from "react-cookie/cjs/useCookies";
import { fetchUserInfo } from "../../redux/userInfoSlice";

const AppBar = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies([]);

  useEffect(() => {
    cookies?.token?.access && dispatch(fetchUserInfo(cookies.token)).then((data) => data);
  }, [cookies.token]);

  const [activeNotification, setActiveNotifications] = useState(false);
  const userInfoRes = useSelector((state) => state?.userInfoSlice?.res);
  const userInfoSubSlice = useSelector((state) => state?.userInfoSlice);
  useEffect(() => {
    if (pic2) return;
    else if (userInfoRes?.files && userInfoRes?.files[0]?.aws_object_key) {
      getImage(userInfoRes?.files[0]?.aws_object_key).then((data) => {
        // setPicture(data);
        dispatch(updateProfilePicture(data));
      });
    } else if (userInfoRes?.files && userInfoRes?.files.length == 0) dispatch(updateProfilePicture());
  }, [userInfoSubSlice]);

  const pic2 = useSelector((state) => state.userInfoSlice.profilePicture);

  return (
    <div className="appBar_container">
      {/* <div className="appBar_container_content">
        <SearchIcon />
        <input placeholder="Search..." />
        <FilterIcon />
      </div> */}
      <div className="appBar_profile" style={{ marginLeft: "auto" }}>
        <div style={{ position: "relative" }}>
          <NotificationIcon
            className={`appBar_profile-notification-icon ${activeNotification ? "active" : ""}`}
            onClick={() => setActiveNotifications(!activeNotification)}
          />
          {activeNotification && <NotificationPage />}
        </div>
        <div className="appBar_profile-info">
          {(userInfoSubSlice.status == "success" && userInfoRes?.name) || "..."}
          <span>{userInfoRes?.profile?.user?.role || "..."}</span>
        </div>
        <section style={{ borderRadius: "4px", overflow: "hidden" }}>
          <span></span>
          <Link
            to="/profile"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48px",
              height: "48px",
            }}
          >
            <img
              src={pic2 ? "data:image/jpeg;base64," + pic2 : Maya}
              alt="profile image"
              style={{ borderRadius: "4px", width: "48px", height: "48px" }}
            />
          </Link>
        </section>
      </div>
    </div>
  );
};

export default AppBar;
